import { Injectable } from '@angular/core';
import { LatestGlobalSearch } from './latest-global-search.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface LatestGlobalSearchesState extends EntityState<LatestGlobalSearch> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'latest-global-searches' })
export class LatestGlobalSearchesStore extends EntityStore<LatestGlobalSearchesState> {

  constructor() {
    super();
  }

}


import { Injectable } from '@angular/core';
import { Configuration } from '../models/configuration';
import { ConfigurationWindow } from '../../../declarations';
import { HiddenFeature } from '../../routes/feeds/state/groups';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    constructor() {
    }

    get value(): Configuration {
        return (window as unknown as ConfigurationWindow).configuration;
    }

    isFeatureActive(feature: string) {
        return !!this.value.featureFlags[feature];
    }
}

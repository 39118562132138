import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import { AutoCompleteComponent } from '../components/auto-complete/auto-complete.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Directive({
    selector: '[appAutoComplete]'
})
export class AutoCompleteDirective implements OnDestroy, OnInit {
    private overlayRef: OverlayRef;
    private subscription: Subscription;
    private manuallyFocusedEmitted = false;

    @Input() appAutoComplete: AutoCompleteComponent;
    @Output() selectValue: EventEmitter<string> = new EventEmitter<string>();
    @Output() submitValue: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private host: ElementRef<HTMLInputElement>,
        private ngControl: NgControl,
        private vcr: ViewContainerRef,
        private overlay: Overlay
    ) {
    }

    get control() {
        return this.ngControl.control;
    }

    get nativeElement() {
        return this.host.nativeElement;
    }

    manuallyFocus() {
        setTimeout(() => {
            this.manuallyFocusedEmitted = true;
            this.nativeElement.focus();
        }, 10);
    }

    closeDropDownAndManuallyFocus() {
        this.closeDropdown();

        setTimeout(() => {
            this.manuallyFocusedEmitted = true;
            this.nativeElement.focus();
        }, 10);
    }

    ngOnInit() {
        this.subscription = fromEvent<FocusEvent>(this.nativeElement, 'focusin').subscribe(() => {
            this.openDropdown();

            if (this.manuallyFocusedEmitted) {
                this.manuallyFocusedEmitted = false;
            }

            this.appAutoComplete.optionsClick()
                .pipe(
                    takeUntil(this.overlayRef.detachments()),
                    tap(option => {
                        this.control.setValue(option.value);
                        this.selectValue.emit(option.value);
                        this.closeDropDownAndManuallyFocus();
                    })
                ).subscribe();

            this.appAutoComplete.optionsMouseEnter()
                .pipe(
                    takeUntil(this.overlayRef.detachments()),
                    tap(option => {
                        this.appAutoComplete.unselectActiveOption();
                        option.active = true;
                    })
                ).subscribe();

            this.appAutoComplete.optionsMouseLeave()
                .pipe(
                    takeUntil(this.overlayRef.detachments()),
                    tap(option => {
                        option.active = false;
                    })
                ).subscribe();
        });

        this.subscription.add(
            fromEvent<MouseEvent>(this.nativeElement, 'click').pipe(
                tap(event => {
                    this.openDropdown();
                })
            ).subscribe()
        );

        this.subscription.add(
            fromEvent<KeyboardEvent>(this.nativeElement, 'keydown').pipe(
                tap(event => event.key !== 'Escape' && this.openDropdown()),
                filter(event => ['ArrowUp', 'ArrowDown', 'Enter', 'Escape'].includes(event.key)),
                tap(event => {
                    event.preventDefault();
                    event.stopImmediatePropagation();

                    switch (event.key) {
                        case 'Escape':
                            this.closeDropdown();
                            break;
                        case 'ArrowUp':
                            this.appAutoComplete.selectPrevious();
                            break;
                        case 'ArrowDown':
                            this.appAutoComplete.selectNext();
                            break;
                        case 'Enter':
                            const activeOption = this.appAutoComplete.getActiveOption();

                            if (activeOption) {
                                this.control.setValue(activeOption.value);
                                this.selectValue.emit(activeOption.value);
                            } else {
                                this.submitValue.emit(true);
                            }

                            this.closeDropDownAndManuallyFocus();
                            break;
                    }
                })
            ).subscribe()
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openDropdown() {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                width: this.nativeElement.offsetWidth,
                // maxHeight: 40 * 3,
                // backdropClass: '',
                scrollStrategy: this.overlay.scrollStrategies.reposition(),
                positionStrategy: this.getOverlayPosition(),
                // hasBackdrop: true,
                backdropClass: 'cdk-overlay-transparent-backdrop'
            });

            overlayClickOutside(this.overlayRef, this.nativeElement).subscribe(() => this.closeDropdown());
        }

        if (!this.manuallyFocusedEmitted && !this.overlayRef.hasAttached()) {
            const template = new TemplatePortal(this.appAutoComplete.rootTemplate, this.vcr);
            this.overlayRef.attach(template);
        }

    }

    private closeDropdown() {
        if (this.overlayRef) {
            this.overlayRef.detach();
            this.overlayRef = null;
        }
    }

    private getOverlayPosition() {
        const positions = [
            new ConnectionPositionPair(
                { originX: 'start', originY: 'bottom' },
                { overlayX: 'start', overlayY: 'top' }
            )
        ];

        return this.overlay
            .position()
            .flexibleConnectedTo(this.nativeElement)
            .withPositions(positions)
            .withFlexibleDimensions(false)
            .withPush(false);
    }
}


export function overlayClickOutside(overlayRef: OverlayRef, origin: HTMLElement) {
    return fromEvent<MouseEvent>(document, 'click')
        .pipe(
            filter(event => {
                const clickTarget = event.target as HTMLElement;
                const notOrigin = clickTarget !== origin; // the input
                const notOverlay = !!overlayRef && (overlayRef.overlayElement.contains(clickTarget) === false); // the autocomplete

                return notOrigin && notOverlay;
            }),
            takeUntil(overlayRef.detachments())
        );
}

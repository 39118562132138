<ng-template #template>
    <clr-dropdown>
        <button class="btn btn-link" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="language" size="24"></clr-icon>
            <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <button [ngClass]="{'active': language.id === activeLang}" *ngFor="let language of languages"
                    (click)="onLanguageClick(language.id);"
                    clrDropdownItem>{{language.label}}</button>
        </clr-dropdown-menu>
    </clr-dropdown>
</ng-template>

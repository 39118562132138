import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ApplicationViewStore } from './application-view.store';
import { ApplicationView } from './application-view.model';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ApplicationViewService {
    private views$: BehaviorSubject<ApplicationView[]>;

    constructor(private applicationViewStore: ApplicationViewStore,
                private http: HttpClient,
                private router: Router) {
    }

    get() {
        return of([
            /*{
                id: 'HOME',
                icon: 'home',
                label: 'Home',
                path: '/app/home'
            },*/
            {
                id: 'FEEDS',
                icon: 'file-share',
                label: 'Feeds',
                path: '/app/feeds'
            },
            /*{
                id: 'CHAT',
                icon: 'talk-bubbles',
                label: 'Chat',
                path: '/app/chat'
            }*/
        ]).pipe(
            tap(entities => {
                this.applicationViewStore.set(entities);
            }),
            switchMap(entities =>
                this.router.events.pipe(
                    filter(event => event instanceof NavigationEnd),
                    tap(value => {
                        const currentActive = entities.find(view => {
                            return this.router.isActive(this.router.createUrlTree([view.path]), false);
                        });

                        if (currentActive) {
                            this.applicationViewStore.setActive(currentActive.id);
                        }
                    })
                )
            )
        );
    }

    fetch() {
        this.get().pipe(take(1)).subscribe();
    }

    add(applicationView: ApplicationView) {
        this.applicationViewStore.add(applicationView);
    }

    update(id, applicationView: Partial<ApplicationView>) {
        this.applicationViewStore.update(id, applicationView);
    }

    remove(id: string) {
        this.applicationViewStore.remove(id);
    }

    updateGroupingListWidth(width: number) {
        this.applicationViewStore.updateCategoryListWidth(width);
    }

    updateContentsListWidth(width: number) {
        this.applicationViewStore.updateContentsListWidth(width);
    }

    updateContentsQuery(query: string) {
        this.applicationViewStore.updateContentsQuery(query);
    }
}

import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { TabManagerStore } from './tab-manager.store';
import { TabManager } from './tab-manager.model';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TabManagerService {

    constructor(private tabManagerStore: TabManagerStore,
                private http: HttpClient) {
    }

    setUpTabManagerGroup(id: string, tabs = [], defaultTabId: string = null) {
        this.tabManagerStore.setUpTabManagerGroup(id, tabs, defaultTabId);
    }

    setTabActiveInGroup(groupId: string, tabId: string) {
        this.tabManagerStore.setTabActiveInGroup(groupId, tabId);
    }
}

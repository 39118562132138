<header class="header header-1">
    <div class="branding">
        <a routerLink="/" class="nav-link">
            <ng-container *ngIf="!hasEnvironmentLogo else: realLogo">
                <i class="mercury-logo">{{'shared.components.topbar.logo' | transloco}}</i>
                <span class="title">{{'shared.components.topbar.title' | transloco}}</span>
            </ng-container>
            <ng-template #realLogo>
                <img [src]="logoPath" [alt]="'shared.components.topbar.logo' | transloco" class="topbar__logo" />
            </ng-template>
        </a>
    </div>

    <div style="display: flex; flex: 1 0 auto; margin-left: 60px">
        <div [style.width]="((groupingListWidth$ | ngrxPush) - 60) + 'px'" class="header-nav clr-justify-content-end">
            <a routerLink="/app/feeds" (click)="onClickContentAdd($event)"
               class="nav-link nav-icon create-content-link" [title]="'shared.components.topbar.createContent' | transloco">
                <clr-icon shape="form"></clr-icon>
            </a>
        </div>

        <app-global-search-bar *ngIf="!config.value.featureFlags.disableSearch"></app-global-search-bar>

        <div class="header-actions">
            <app-language-switcher></app-language-switcher>

            <app-topbar-settings-panel *ngIf="!config.value.featureFlags.disableSettings"></app-topbar-settings-panel>

            <app-alert-button *ngIf="!config.value.featureFlags.disableNotifications"></app-alert-button>
        </div>

        <app-topbar-auth-panel></app-topbar-auth-panel>
    </div>
</header>

<nav class="subnav" *ngIf="!isNewsletterReviewHidden">
    <ul class="nav">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active' : page === 'feeds'}" routerLink="/app/feeds">{{'shared.components.topbar.articles' | transloco}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active' : page === 'review'}" routerLink="/app/review">
                {{'shared.components.topbar.review' | transloco}} <span class="badge badge-blue">{{ (reviewList$ | async).length }}</span>
            </a>
        </li>
    </ul>
</nav>
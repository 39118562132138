import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LatestGlobalSearchesStore, LatestGlobalSearchesState } from './latest-global-searches.store';

@Injectable({ providedIn: 'root' })
export class LatestGlobalSearchesQuery extends QueryEntity<LatestGlobalSearchesState> {

  constructor(protected store: LatestGlobalSearchesStore) {
    super(store);
  }

}

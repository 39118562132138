import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-contents-layout',
    templateUrl: './contents-layout.component.html',
    styleUrls: ['./contents-layout.component.scss']
})
export class ContentsLayoutComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }

}

import { ID } from '@datorama/akita';

export interface LatestGlobalSearch {
    id: string;
    date: Date;
}

export function createLatestGlobalSearch(params: Partial<LatestGlobalSearch>) {
    return {
        date: new Date(),
        ...params
    } as LatestGlobalSearch;
}

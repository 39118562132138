import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { AuthStore, AuthState } from './auth.store';
import { AuthToken } from './auth.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
    isLoggedIn$ = this.select(state => toBoolean(state.user));
    currentUser$ = this.select(state => state.user);
    refreshToken$ = this.select(state => state.jwt.refreshToken);

    private jwtHelperService: JwtHelperService;

    constructor(protected store: AuthStore) {
        super(store);

        this.jwtHelperService = new JwtHelperService();
    }

    isLoggedIn(): boolean {
        return toBoolean(this.getValue().user);
    }

    getAuthToken(): AuthToken {
        return this.getValue().jwt;
    }

    isRefreshTokenStillValid(): boolean {
        const authToken = this.getAuthToken();

        return authToken && !this.jwtHelperService.isTokenExpired(authToken.refreshToken);
    }

    isTokenStillValid(): boolean {
        const authToken = this.getAuthToken();

        return authToken && !this.jwtHelperService.isTokenExpired(authToken.token);
    }

    getCurrentUser() {
        return this.store.getValue().user;
    }

    isAuthorIdCurrentUser(authorId: string) {
        return this.getCurrentUser().id === authorId;
    }
}

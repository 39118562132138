import { Injectable } from '@angular/core';
import { ApplicationView } from './application-view.model';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface ApplicationViewState extends EntityState<ApplicationView>, ActiveState {
    ui: {
        groupingListWidth: number;
        contentsListWidth: number;
    };

    contentsQuery: string;
}

const initialState = {
    ui: {
        groupingListWidth: 350,
        contentsListWidth: 550,
    },

    contentsQuery: ''
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application-view' })
export class ApplicationViewStore extends EntityStore<ApplicationViewState> {

    constructor() {
        super(initialState);
    }

    updateCategoryListWidth(width: number) {
        this.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                groupingListWidth: width
            }
        }));
    }

    updateContentsListWidth(width: number) {
        this.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                contentsListWidth: width
            }
        }));
    }

    updateContentsQuery(query: string) {
        this.update(state => ({
            ...state,
            contentsQuery: query
        }));
    }
}


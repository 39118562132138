import { EntityState, EntityStore, ID, StoreConfig } from '@datorama/akita';
import { Alert } from './alert.model';
import { Injectable } from '@angular/core';

export interface AlertsState extends EntityState<Alert> {
    ui: {
        nativeNotificationsPermitted: boolean;
        mutedGroupings: { [key: string]: boolean; };
        muteNotificationSounds: boolean;
    };
}

const initialState = {
    ui: {
        nativeNotificationsPermitted: false,
        mutedGroupings: {},
        muteNotificationSounds: false
    }

};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'alerts' })
export class AlertsStore extends EntityStore<AlertsState> {

    constructor() {
        super(initialState);
    }

    updateNativeNotificationsPermission(value: boolean) {
        this.update(state => ({
            ui: {
                ...state.ui,
                nativeNotificationsPermitted: value
            }
        }));
    }

    removeAlert(id: string) {
        this.remove(id);
    }

    removeAllAlerts() {
        this.remove();
    }

    setMutedGroupingState(id: string, value: boolean) {
        this.update(state => ({
            ui: {
                ...state.ui,
                mutedGroupings: {
                    ...state.ui.mutedGroupings,
                    [id]: value
                }
            }
        }));
    }

    updateMuteNotificationSounds(value: boolean) {
        this.update(state => ({
            ui: {
                ...state.ui,
                muteNotificationSounds: value
            }
        }));
    }

    changeAlertUnreadState(alert: Alert, value: boolean = false) {
        this.update(alert.id, ({
            unread: value
        }));
    }
}

import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ApplicationViewStore, ApplicationViewState } from './application-view.store';

@Injectable({ providedIn: 'root' })
export class ApplicationViewQuery extends QueryEntity<ApplicationViewState> {
    groupingListWidth$ = this.select(state => state.ui.groupingListWidth);
    contentsQuery$ = this.select(state => state.contentsQuery);
    contentsListWidth$ = this.select(state => state.ui.contentsListWidth);

    constructor(protected store: ApplicationViewStore) {
        super(store);
    }

}

import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    private renderer: Renderer2;
    private themeLoader: any;

    constructor(
        private rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) private doc
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
        this.themeLoader = this.renderer.createElement('link');
        this.renderer.setProperty(this.themeLoader, 'rel', 'stylesheet');
        this.renderer.appendChild(this.doc.head, this.themeLoader);
    }

    applyTheme(name: string) {
        this.renderer.setProperty(this.themeLoader, 'href', `${name}.theme.css`);
    }
}

import {
    Component,
    ElementRef,
    Input,
    SimpleChanges,
    OnDestroy,
    OnChanges,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import OverlayScrollbars from 'overlayscrollbars';

@Component({
    selector: 'app-overlay-scrollbars',
    host: { class: 'os-host' },
    template: `
        <div class="os-resize-observer-host"></div>
        <div class="os-padding">
            <div class="os-viewport" #viewPort>
                <div class="os-content">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-horizontal ">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle"></div>
            </div>
        </div>
        <div class="os-scrollbar os-scrollbar-vertical">
            <div class="os-scrollbar-track">
                <div class="os-scrollbar-handle"></div>
            </div>
        </div>
        <div class="os-scrollbar-corner"></div>
    `,
    styles: [':host { display: block; }']
})
export class AppOverlayScrollbarsComponent implements OnDestroy, OnChanges, AfterViewInit {

    @Input('options') private _options: OverlayScrollbars.Options;
    @Input('extensions') private _extensions: OverlayScrollbars.Extensions;
    private _osInstance: OverlayScrollbars | null = null;
    private _osTargetRef: ElementRef;

    @ViewChild('viewPort', { static: true })
    private _osViewPort: ElementRef;

    constructor(_osTargetRef: ElementRef) {
        this._osTargetRef = _osTargetRef;

        OverlayScrollbars.extension(
            'horizontalScrolling',
            // tslint:disable-next-line:only-arrow-functions
            function(defaultOptions: {}, compatibility: OverlayScrollbars.Compatibility, framework: any): OverlayScrollbars.Extension {
                const osInstance = this;
                const extension: Partial<OverlayScrollbars.Extension> = {};

                const scrollHorizontally = (e) => {
                    // tslint:disable-next-line:deprecation
                    e = window.event || e;
                    e.preventDefault();

                    const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
                    const amount = Math.abs(delta * 40);
                    const prefix = delta < 0 ? '+=' : '-=';

                    osInstance.scroll({ x: `${prefix} ${amount}px` });
                };

                extension.added = (options) => {
                    const instanceElements = osInstance.getElements();
                    const viewport = instanceElements.target as (HTMLElement & { attachEvent: (a: string, b: (e: Event) => void) => void });

                    if (viewport.addEventListener) {
                        // IE9, Chrome, Safari, Opera
                        viewport.addEventListener('mousewheel', scrollHorizontally, false);
                        // Firefox
                        viewport.addEventListener('DOMMouseScroll', scrollHorizontally, false);
                    } else if (viewport.attachEvent) {
                        // IE 6/7/8
                        viewport.attachEvent('onmousewheel', scrollHorizontally);
                    }
                };

                return extension as OverlayScrollbars.Extension;
            });
    }

    osInstance(): OverlayScrollbars | null {
        return this._osInstance;
    }

    get osViewport(): HTMLDivElement {
        return this._osViewPort.nativeElement;
    }

    osTarget(): HTMLDivElement | null {
        return this._osTargetRef.nativeElement || null;
    }

    ngAfterViewInit() {
        this._osInstance = OverlayScrollbars(this.osTarget(), this._options || {}, this._extensions);
    }

    ngOnDestroy() {
        if (OverlayScrollbars.valid(this._osInstance)) {
            this._osInstance.destroy();
            this._osInstance = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const optionsChange = changes._options;
        if (optionsChange && OverlayScrollbars.valid(this._osInstance)) {
            this._osInstance.options(optionsChange.currentValue);
        }
    }
}

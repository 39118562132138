<div class="header-actions">
    <clr-dropdown *ngIf="currentUser$ | ngrxPush as currentUser">
        <a href="javascript://" class="nav-link nav-icon" aria-label="settings" clrDropdownTrigger *ngIf="isLoggedIn$ | ngrxPush">
            <clr-icon shape="user"></clr-icon>
            {{ currentUser.firstName }}
            <clr-icon shape="caret down" class="caret-icon"></clr-icon>
        </a>

        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
	        <div class="top-bar__auth-panel__container">
				<app-auth-panel-user [user]="currentUser"></app-auth-panel-user>
	        </div>

			<ng-container *ngIf="!config.value.featureFlags.minimizeProfile">
				<div class="dropdown-divider" role="separator" aria-hidden="true"></div>

				<clr-dropdown>
					<button clrDropdownTrigger>
						<clr-icon shape="circle" class="is-success is-solid" size="12"></clr-icon>
						{{'shared.components.topbarAuthPanel.available' | transloco}}
					</button>
					<clr-dropdown-menu [clrPosition]="'bottom-left'">
						<button clrDropdownItem>
							<clr-icon shape="circle" class="is-danger is-solid" size="12"></clr-icon>
							{{'shared.components.topbarAuthPanel.busy' | transloco}}
						</button>
						<button clrDropdownItem>
							<clr-icon shape="minus-circle" class="is-danger is-solid" size="12"></clr-icon>
							{{'shared.components.topbarAuthPanel.doNotDisturb' | transloco}}
						</button>
						<button clrDropdownItem>
							<clr-icon shape="clock" class="is-warning is-solid" size="12"></clr-icon>
							{{'shared.components.topbarAuthPanel.backSoon' | transloco}}
						</button>
						<button clrDropdownItem>
							<clr-icon shape="circle" class="is-warning is-solid" size="12"></clr-icon>
							{{'shared.components.topbarAuthPanel.away' | transloco}}
						</button>
					</clr-dropdown-menu>
				</clr-dropdown>

				<button clrDropdownItem>
					<clr-icon shape="chat-bubble" size="16"></clr-icon>
					{{'shared.components.topbarAuthPanel.changeStatusMessage' | transloco}}
				</button>

		        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>

		        <button clrDropdownItem>
					{{'shared.components.topbarAuthPanel.aboutMercury' | transloco}}
		        </button>

			</ng-container>

	        <div class="dropdown-divider" role="separator" aria-hidden="true"></div>

	        <button clrDropdownItem (click)="handleClickSignOut($event)">
		        {{'shared.components.topbarAuthPanel.signOut' | transloco}}
	        </button>
        </clr-dropdown-menu>
    </clr-dropdown>
</div>

import { ApiResponse, BaseApi } from './base.api';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';

export abstract class MercuryBrokerApi extends BaseApi<ApiResponse> {
    protected constructor(
        baseName: string,
        env: boolean,
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        const mercuryApi = configurationService.value.api.mercury;
        const basePath = [ mercuryApi.baseUrl, mercuryApi.broker ];

        if (env) {
            basePath.push('environments');
            basePath.push(configurationService.value.environmentId);
        }

        if (baseName) {
            basePath.push(baseName);
        }

        super(basePath.join('/'), http, true);
    }
}

import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { LatestGlobalSearchesStore } from './latest-global-searches.store';
import { LatestGlobalSearch } from './latest-global-search.model';

@Injectable({ providedIn: 'root' })
export class LatestGlobalSearchesService {

    constructor(private latestGlobalSearchesStore: LatestGlobalSearchesStore,
                private http: HttpClient) {
    }

    add(latestGlobalSearch: LatestGlobalSearch) {
        this.latestGlobalSearchesStore.add(latestGlobalSearch);
    }

    update(id, latestGlobalSearch: Partial<LatestGlobalSearch>) {
        this.latestGlobalSearchesStore.update(id, latestGlobalSearch);
    }

    remove(id: string) {
        this.latestGlobalSearchesStore.remove(id);
    }
}

import { ID } from '@datorama/akita';

export enum GlobalSearchResultType {
    LATEST = 'LATEST',
    DEFAULT = 'DEFAULT',
    CONTENT = 'CONTENT',
}

export interface GlobalSearchResult<T = any> {
    id: ID;
    type: GlobalSearchResultType;
    meta?: Partial<T>;
}

export function createGlobalSearchResult<T>(params: Partial<GlobalSearchResult<T>>) {
    return {
        type: GlobalSearchResultType.DEFAULT,
        ...params
    } as GlobalSearchResult;
}

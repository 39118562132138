import { Injectable } from '@angular/core';
import { MercuryBrokerApi } from '../../../../shared/api/mercury-broker.api';
import { Observable } from 'rxjs';
import { Content, ContentComment, ContentCommentReply, ContentProduct, ContentSessionResponse } from './content.model';
import { map } from 'rxjs/operators';
import { ApiResponse } from '../../../../shared/api/base.api';
import { getDynamicQueryParams, transformCommentForRequest, transformCommentToRequestBody } from './contents.transform';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../../../shared/services/configuration.service';

export interface QueryCriteria {
    key: string;
    operator: string;
    value: any;
}

@Injectable({ providedIn: 'root' })
export class ContentsApi extends MercuryBrokerApi {
    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super('contents', true, http, configurationService);
    }

    getContents(offset?: number, limit?: number): Observable<Content[]> {
        return this.get('', {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(map(response => response.data));
    }

    getContent(id: string): Observable<Content> {
        return this.get(`${id}`).pipe(map(response => response.data));
    }

    getContentsWithQuery(criteria: QueryCriteria[] = [], offset?: number, limit?: number): Observable<Content[]> {
        const fields = criteria.map(c => c.key);

        return this.post('query', {
            fields,
            criteria
        }, {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(map(response => response.data));
    }

    createContent(content: Partial<Content>): Observable<ApiResponse> {
        return this.post('', content);
    }

    updateContent(content: Partial<Content>): Observable<ApiResponse> {
        return this.put('', content);
    }

    createSession(group: string, tab: string): Observable<ContentSessionResponse> {
        return this.post('sessions', {
            navigationItem: group,
            tab
        }).pipe(map(response => response.data));
    }

    createSearchSession(searchTerm: string): Observable<ContentSessionResponse> {
        return this.post('search/sessions', {
            searchTerm
        }).pipe(map(response => response.data));
    }

    getContentsFromSearchSession(sessionId: string, offset?: number, limit?: number) {
        return this.get(`search/sessions/${sessionId}`, {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(
            map(res => res.data),
            map(data => data.result ? data.result : data)
        );
    }

    getContentsFromSession(sessionId: string, offset?: number, limit?: number) {
        return this.get(`sessions/${sessionId}`, {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(
            map(res => res.data),
            map(data => data.result ? data.result : data)
        );
    }

    deleteContent(content: Content): Observable<ApiResponse> {
        return this.delete(`${content.id}`);
    }

    deleteContentById(id: string): Observable<ApiResponse> {
        return this.delete(`${id}`);
    }

    createCommentForContent(content: Content, comment: Partial<ContentComment>): Observable<ApiResponse> {
        return this.post(
            `${content.id}/comments`,
            transformCommentForRequest(comment)
        );
    }

    updateCommentForContent(contentId: string, comment: ContentComment): Observable<ApiResponse> {
        return this.put(`${contentId}/comments`, transformCommentToRequestBody(comment));
    }

    getAllComments(offset?: number, limit?: number): Observable<ContentComment[]> {
        return this.get('comments', {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(map(res => res.data));
    }

    getCommentsForContent(content: Content, offset?: number, limit?: number): Observable<ContentComment[]> {
        return this.getCommentsForContentId(content.id, offset, limit);
    }

    getCommentsForContentId(id: string, offset?: number, limit?: number): Observable<ContentComment[]> {
        return this.get(`${id}/comments`, {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(map(res => res.data));
    }

    getRepliesForCommentId(contentId: string, commentId: string, offset?: number, limit?: number): Observable<ContentCommentReply[]> {
        return this.get(`${contentId}/comments/${commentId}/replies`, {
            params: getDynamicQueryParams(offset, limit)
        }).pipe(map(res => res.data));
    }

    createReplyForComment(content: Content, comment: ContentComment, reply: Partial<ContentCommentReply>) {
        return this.createReplyForCommentId(content.id, comment.id, reply);
    }

    createReplyForCommentId(contentId: string, commentId: string, reply: Partial<ContentCommentReply>): Observable<ApiResponse> {
        return this.post(
            `${contentId}/comments/${commentId}/replies`,
            transformCommentForRequest(reply)
        );
    }

    updateReplyForCommentId(contentId: string, commentId: string, reply: ContentCommentReply): Observable<ApiResponse> {
        return this.put(
            `${contentId}/comments/${commentId}/replies/${reply.id}`,
            transformCommentToRequestBody(reply)
        );
    }

    deleteReplyFromCommentId(contentId: string, commentId: string, replyId: string): Observable<ApiResponse> {
        return this.delete(
            `${contentId}/comments/${commentId}/replies/${replyId}`
        );
    }

    markContentAsSeen(contentId: string): Observable<ApiResponse> {
        return this.post(
            'seen',
            {
                contentId
            }
        );
    }

    addBookmark(contentId: string) {
        return this.post(`${contentId}/bookmarks`, {});
    }

    removeBookmark(contentId: string) {
        return this.delete(`${contentId}/bookmarks`);
    }

}

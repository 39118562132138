import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    Input, OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { TabTemplateRefComponent } from './tab-template-ref.component';
import { TabManagerQuery } from '../../state/tab-manager/tab-manager.query';
import { TabManagerService } from '../../state/tab-manager/tab-manager.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-tab-content',
    template: `
        <ng-template #template>
            <section role="tabpanel" class="tab-content" [class.active]="isActive">
                <ng-content></ng-content>
            </section>
        </ng-template>
    `,
    styles: []
})
export class TabContentComponent implements OnInit, AfterViewInit, OnDestroy {
    tabContentId: string;
    _tabsId: string;

    @ViewChild('template', { static: true }) template;

    private tabSubscription: Subscription;

    isActive = false;

    constructor(
        public el: ElementRef,
        private cfr: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private cd: ChangeDetectorRef,
        private tabManagerQuery: TabManagerQuery,
        private tabManagerService: TabManagerService
    ) {
    }

    set tabsId(id: string) {
        this._tabsId = id;

        if (this.tabSubscription) {
            this.tabSubscription.unsubscribe();
        }

        this.tabSubscription = this.tabManagerQuery.getActiveTabsIdFromGroup$(id).pipe(delay(0)).subscribe(activeId => {
            this.isActive = activeId === this.tabContentId;
        });
    }


    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
        if (this.tabSubscription) {
            this.tabSubscription.unsubscribe();
        }
    }
}

import * as i0 from '@angular/core';
import { InjectionToken, inject, PLATFORM_ID, Injectable, Optional, Inject } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { BehaviorSubject, Subject, merge, EMPTY, timer } from 'rxjs';
import { filter, map, distinctUntilChanged, debounce, mapTo } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
const filterNil = source => source.pipe(filter(value => value !== null && typeof value !== 'undefined'));
function coerceArray(value) {
  if (isNil(value)) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
}
function isNil(v) {
  return v === null || v === undefined;
}
function clone(value) {
  return isObject(value) ? Object.assign({}, value) : Array.isArray(value) ? [...value] : value;
}
function isValidDate(value) {
  return value && Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value);
}
function isObject(val) {
  if (val == null || Array.isArray(val) || isValidDate(val)) {
    return false;
  }
  return typeof val === 'object';
}
const removeKeys = ['dirty', 'disabled', 'invalid', 'pending', 'errors', 'pristine', 'touched', 'valid'];
function filterControlKeys(value) {
  return filterKeys(value, key => removeKeys.includes(key));
}
function filtrArrayKeys(arr, cb) {
  return arr.reduce((acc, control, index) => {
    acc[index] = filterKeys(control, cb);
    return acc;
  }, []);
}
function filterKeys(obj, cb) {
  const filtered = {};
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (cb(key) === false) {
      if (isObject(value)) {
        filtered[key] = filterKeys(value, cb);
      } else if (Array.isArray(value) && key === 'controls') {
        filtered[key] = filtrArrayKeys(value, cb);
      } else {
        filtered[key] = value;
      }
    }
  }
  return filtered;
}
function isBrowser() {
  return typeof window !== 'undefined';
}
function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
}
function toStore(name, control) {
  let value;
  if (control instanceof FormControl) {
    value = buildValue(control);
    return value;
  }
  if (control instanceof FormGroup || control instanceof FormArray) {
    value = buildValue(control);
    for (const key of Object.keys(control.controls)) {
      const current = control.controls[key];
      if (current instanceof FormGroup || current instanceof FormArray) {
        value.controls[key] = toStore(name, current);
      } else {
        value.controls[key] = buildValue(current);
      }
    }
  }
  return value;
}
function handleFormArray(formValue, control, arrControlFactory) {
  if (control instanceof FormArray) {
    clearFormArray(control);
    if (!arrControlFactory) {
      throw new Error('Please provide arrControlFactory');
    }
    formValue.forEach((v, i) => control.insert(i, arrControlFactory(v)));
  } else {
    Object.keys(formValue).forEach(controlName => {
      const value = formValue[controlName];
      if (Array.isArray(value) && control.get(controlName) instanceof FormArray) {
        if (!arrControlFactory || arrControlFactory && !(controlName in arrControlFactory)) {
          throw new Error(`Please provide arrControlFactory for ${controlName}`);
        }
        const current = control.get(controlName);
        const fc = arrControlFactory[controlName];
        clearFormArray(current);
        value.forEach((v, i) => current.insert(i, fc(v)));
      }
    });
  }
}
function deleteControl(snapshot, controls) {
  return Object.keys(snapshot).reduce((acc, currentFormName) => {
    if (controls.includes(currentFormName) === false) {
      acc[currentFormName] = snapshot[currentFormName];
    }
    return acc;
  }, {});
}
function findControl(control, path) {
  const [first, ...rest] = path.split('.');
  if (rest.length === 0) {
    return control.controls[first];
  }
  return rest.reduce((current, name) => {
    return current.controls.hasOwnProperty(name) ? current.controls[name] : null;
  }, control.controls[first]);
}
function buildValue(control) {
  const value = {
    value: clone(control.value),
    rawValue: control.getRawValue ? control.getRawValue() : null,
    valid: control.valid,
    dirty: control.dirty,
    invalid: control.invalid,
    disabled: control.disabled,
    errors: control.errors,
    touched: control.touched,
    pristine: control.pristine,
    pending: control.pending,
    untouched: control.untouched
  };
  if (control instanceof FormGroup || control instanceof FormArray) {
    value['controls'] = control instanceof FormArray ? [] : {};
  }
  return value;
}
function clearFormArray(control) {
  while (control.length !== 0) {
    control.removeAt(0);
  }
}
const defaults = {
  storage: {
    key: 'ngFormsManager'
  },
  debounceTime: 300
};
function mergeConfig(defaults, providerConfig = {}, inlineConfig) {
  return Object.assign(Object.assign(Object.assign(Object.assign({}, defaults), providerConfig), inlineConfig), {
    storage: Object.assign(Object.assign(Object.assign({}, defaults.storage), providerConfig.storage), inlineConfig.storage)
  });
}
class NgFormsManagerConfig {
  constructor(config = {}) {
    this.config = config;
  }
  merge(inline = {}) {
    return mergeConfig(defaults, this.config, inline);
  }
}
const NG_FORMS_MANAGER_CONFIG = new InjectionToken('NG_FORMS_MANAGER_CONFIG', {
  providedIn: 'root',
  factory: () => {
    return new NgFormsManagerConfig();
  }
});
class FormsStore {
  constructor(state) {
    this.state = state;
    this.store = new BehaviorSubject(state);
  }
  select(project) {
    return this.store.asObservable().pipe(map(project), distinctUntilChanged());
  }
  getValue() {
    return this.store.getValue();
  }
  set(state) {
    this.store.next(state);
  }
  update(state) {
    this.store.next(Object.assign({}, this.getValue(), state));
  }
}

// https://github.com/epoberezkin/fast-deep-equal
function isEqual(a, b) {
  if (a === b) {
    return true;
  }
  if (a && b && typeof a == 'object' && typeof b == 'object') {
    if (a.constructor !== b.constructor) {
      return false;
    }
    var length, i, keys;
    if (Array.isArray(a)) {
      length = a.length;
      if (length != b.length) {
        return false;
      }
      for (i = length; i-- !== 0;) {
        if (!isEqual(a[i], b[i])) {
          return false;
        }
      }
      return true;
    }
    if (a.constructor === RegExp) {
      return a.source === b.source && a.flags === b.flags;
    }
    if (a.valueOf !== Object.prototype.valueOf) {
      return a.valueOf() === b.valueOf();
    }
    if (a.toString !== Object.prototype.toString) {
      return a.toString() === b.toString();
    }
    keys = Object.keys(a);
    length = keys.length;
    if (length !== Object.keys(b).length) {
      return false;
    }
    for (i = length; i-- !== 0;) {
      if (!Object.prototype.hasOwnProperty.call(b, keys[i])) {
        return false;
      }
    }
    for (i = length; i-- !== 0;) {
      var key = keys[i];
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  // true if both NaN, false otherwise
  return a !== a && b !== b;
}

/**
 * Injection Token to safely inject
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage} to Angular DI
 */
const SESSION_STORAGE_TOKEN = new InjectionToken('SESSION_STORAGE_TOKEN', {
  providedIn: 'root',
  factory: () => isPlatformBrowser(inject(PLATFORM_ID)) ? sessionStorage : undefined
});
/**
 * Injection Token to safely inject
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage} to Angular DI
 */
const LOCAL_STORAGE_TOKEN = new InjectionToken('LOCAL_STORAGE_TOKEN', {
  providedIn: 'root',
  factory: () => isPlatformBrowser(inject(PLATFORM_ID)) ? localStorage : undefined
});
/**
 * Injection Token to inject custom storage approach for persistence; must implement
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Storage} interface
 */
const FORMS_MANAGER_STORAGE = new InjectionToken('FORMS_MANAGER_STORAGE', {
  providedIn: 'root',
  factory: () => isPlatformBrowser(inject(PLATFORM_ID)) ? inject(LOCAL_STORAGE_TOKEN) : undefined
});
/**
 * Value provider that injects usage of
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage} for persistence
 */
const FORMS_MANAGER_SESSION_STORAGE_PROVIDER = {
  provide: FORMS_MANAGER_STORAGE,
  useExisting: SESSION_STORAGE_TOKEN
};
const NO_DEBOUNCE = Symbol('NO_DEBOUNCE');
// @dynamic; see https://angular.io/guide/angular-compiler-options#strictmetadataemit
class NgFormsManager {
  constructor(config, browserStorage) {
    this.config = config;
    this.browserStorage = browserStorage;
    this.valueChanges$$ = new Map();
    this.instances$$ = new Map();
    this.initialValues$$ = new Map();
    this.destroy$$ = new Subject();
    this.store = new FormsStore({});
  }
  /**
   *
   * @example
   *
   * Whether the control is valid
   *
   * const valid$ = manager.validityChanges('login');
   *
   */
  validityChanges(name, path) {
    return this.controlChanges(name, path).pipe(map(control => control.valid));
  }
  /**
   *
   * Whether the control is valid
   *
   * @example
   *
   * manager.isValid(name);
   *
   */
  isValid(name) {
    return this.hasControl(name) && this.getControl(name).valid;
  }
  /**
   *
   * @example
   *
   * Whether the control is dirty
   *
   * const dirty$ = manager.dirtyChanges('login');
   *
   */
  dirtyChanges(name, path) {
    return this.controlChanges(name, path).pipe(map(control => control.dirty));
  }
  /**
   *
   * @example
   *
   * Whether the control is disabled
   *
   * const disabled$ = manager.disableChanges('login');
   *
   */
  disableChanges(name, path) {
    return this.controlChanges(name, path).pipe(map(control => control.disabled));
  }
  valueChanges(name, path) {
    return this.controlChanges(name, path).pipe(map(control => control.value));
  }
  /**
   *
   * @example
   *
   * Observe the control's errors
   *
   * const errors$ = manager.errorsChanges<Errors>('login');
   * const errors$ = manager.errorsChanges<Errors>('login', 'email');
   *
   */
  errorsChanges(name, path) {
    return this.controlChanges(name, path).pipe(map(control => control.errors));
  }
  controlChanges(name, path) {
    const control$ = this.store.select(state => state[name]).pipe(filterNil);
    if (!path) {
      return control$.pipe(distinctUntilChanged((a, b) => isEqual(a, b)));
    }
    return control$.pipe(map(control => findControl(control, path)), distinctUntilChanged((a, b) => isEqual(a, b)));
  }
  /**
   *
   * Whether the initial control value is deep equal to current value
   *
   * @example
   *
   * const dirty$ = manager.initialValueChanged('settings');
   *
   */
  initialValueChanged(name) {
    if (this.initialValues$$.has(name) === false) {
      console.error(`You should set the withInitialValue option to the ${name} control`);
    }
    return this.valueChanges(name).pipe(map(current => isEqual(current, this.initialValues$$.get(name)) === false));
  }
  /**
   *
   * @example
   *
   * Emits when the control is destroyed
   *
   * const control$ = manager.controlChanges('login').pipe(takeUntil(controlDestroyed('login')))
   *
   */
  controlDestroyed(name) {
    return this.destroy$$.asObservable().pipe(filter(controlName => name === controlName || controlName === '$$ALL'));
  }
  getControl(name, path) {
    if (!path) {
      return this.store.getValue()[name];
    }
    if (this.hasControl(name)) {
      const control = this.getControl(name);
      return findControl(control, path);
    }
    return null;
  }
  getInitialValue(name) {
    return this.initialValues$$.get(name);
  }
  /**
   *
   * @example
   *
   *  Whether the form exists
   *
   * manager.hasControl('login');
   * manager.hasControl('login', 'email');
   *
   */
  hasControl(name, path) {
    return !!this.getControl(name, path);
  }
  /**
   *
   * @example
   *
   * A proxy to the original `patchValue` method
   *
   * manager.patchValue('login', { email: '' });
   *
   */
  patchValue(name, value, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).patchValue(value, options);
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `setValue` method
   *
   * manager.setValue('login', { email: '', name: '' });
   *
   */
  setValue(name, value, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).setValue(value, options);
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `reset` method
   *
   * manager.reset('login', { email: '' });
   *
   */
  reset(name, value, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).reset(value, options);
    }
  }
  /**
   *
   * Sets the initial value for a control
   *
   * @example
   *
   * manager.setInitialValue('login', value);
   *
   */
  setInitialValue(name, value) {
    this.initialValues$$.set(name, value);
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAllAsTouched` method
   *
   * manager.markAllAsTouched('login');
   *
   */
  markAllAsTouched(name) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAllAsTouched();
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAsTouched` method
   *
   * manager.markAsTouched('login');
   *
   */
  markAsTouched(name, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAsTouched(options);
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * Marks the control and all its descendant controls as dirty.
   *
   * manager.markAllAsDirty('login');
   *
   */
  markAllAsDirty(name, options) {
    if (this.instances$$.has(name)) {
      let control = this.instances$$.get(name);
      this.markDescendantsAsDirty(control, options);
      this.updateStore(name, control);
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAsDirty` method
   *
   * manager.markAsDirty('login');
   *
   */
  markAsDirty(name, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAsDirty(options);
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAsPending` method
   *
   * manager.markAsPending('login');
   *
   */
  markAsPending(name, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAsPending(options);
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAsPristine` method
   *
   * manager.markAsPristine('login');
   *
   */
  markAsPristine(name, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAsPristine(options);
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * A proxy to the original `markAsUntouched` method
   *
   * manager.markAsUntouched('login');
   *
   */
  markAsUntouched(name, options) {
    if (this.instances$$.has(name)) {
      this.instances$$.get(name).markAsUntouched(options);
      this.updateStore(name, this.instances$$.get(name));
    }
  }
  /**
   *
   * @example
   *
   * manager.unsubscribe('login');
   *
   */
  unsubscribe(name) {
    if (name) {
      const names = coerceArray(name);
      for (const name of names) {
        if (this.valueChanges$$.has(name)) {
          this.valueChanges$$.get(name).unsubscribe();
        }
        this.valueChanges$$.delete(name);
        this.instances$$.delete(name);
        this.destroy$$.next(name);
      }
    } else {
      this.valueChanges$$.forEach(subscription => {
        subscription.unsubscribe();
        this.destroy$$.next('$$ALL');
      });
      this.valueChanges$$.clear();
      this.instances$$.clear();
    }
  }
  /**
   *
   * @example
   *
   * Removes the control from the store and from browser storage
   *
   * manager.clear('login');
   *
   */
  clear(name) {
    name ? this.deleteControl(name) : this.store.set({});
    this.removeFromStorage();
    this.removeInitialValue(name);
  }
  /**
   *
   * @example
   *
   * Calls unsubscribe and clear
   *
   * manager.destroy('login');
   *
   */
  destroy(name) {
    this.unsubscribe(name);
    this.clear(name);
  }
  /**
   *
   * @example
   *
   * Register a control
   *
   * manager.upsert('login', this.login);
   * manager.upsert('login', this.login, { persistState: true });
   * manager.upsert('login', this.login, { debounceTime: 500 });
   *
   * manager.upsert('login', this.login, { arrControlFactory: value => new FormControl('') });
   *
   */
  upsert(name, control, config = {}) {
    const mergedConfig = this.config.merge(config);
    if (mergedConfig.withInitialValue && this.initialValues$$.has(name) === false) {
      this.setInitialValue(name, control.value);
    }
    if (isBrowser() && config.persistState && this.hasControl(name) === false) {
      const storageValue = this.getFromStorage(mergedConfig.storage.key);
      if (storageValue[name]) {
        this.store.update({
          [name]: mergeDeep(toStore(name, control), storageValue[name])
        });
      }
    }
    /** If the control already exist, patch the control with the store value */
    if (this.hasControl(name) === true) {
      control.patchValue(this.toControlValue(name, control, mergedConfig.arrControlFactory), {
        emitEvent: false
      });
    } else {
      const value = this.updateStore(name, control);
      this.updateStorage(name, value, mergedConfig);
    }
    const unsubscribe = merge(control.statusChanges.pipe(distinctUntilChanged()), ...this.getValueChangeStreams(control)).pipe(debounce(value => value === NO_DEBOUNCE ? EMPTY : timer(mergedConfig.debounceTime))).subscribe(() => {
      const value = this.updateStore(name, control);
      this.updateStorage(name, value, mergedConfig);
    });
    this.valueChanges$$.set(name, unsubscribe);
    this.instances$$.set(name, control);
    return this;
  }
  getValueChangeStreams(control) {
    const streams = [];
    if (control.updateOn === 'blur') {
      streams.push(control.valueChanges.pipe(mapTo(NO_DEBOUNCE)));
    } else {
      streams.push(control.valueChanges);
      if (control instanceof FormGroup) {
        return Object.keys(control.controls).reduce((previous, key) => control.get(key).updateOn === 'blur' ? [...previous, control.get(key).valueChanges.pipe(mapTo(NO_DEBOUNCE))] : [...previous], streams);
      }
    }
    return streams;
  }
  removeFromStorage() {
    var _a;
    (_a = this.browserStorage) === null || _a === void 0 ? void 0 : _a.setItem(this.config.merge().storage.key, JSON.stringify(this.store.getValue()));
  }
  updateStorage(name, value, config) {
    var _a;
    if (isBrowser() && config.persistState) {
      const storageValue = this.getFromStorage(config.storage.key);
      storageValue[name] = filterControlKeys(value);
      (_a = this.browserStorage) === null || _a === void 0 ? void 0 : _a.setItem(config.storage.key, JSON.stringify(storageValue));
    }
  }
  getFromStorage(key) {
    var _a;
    return JSON.parse(((_a = this.browserStorage) === null || _a === void 0 ? void 0 : _a.getItem(key)) || '{}');
  }
  deleteControl(name) {
    this.store.set(deleteControl(this.store.getValue(), coerceArray(name)));
  }
  toControlValue(name, control, arrControlFactory) {
    const currentControl = this.getControl(name);
    const value = currentControl.value;
    /** It means it's not a FormGroup or FormArray */
    if (!currentControl.controls) {
      return value;
    }
    handleFormArray(value, control, arrControlFactory);
    return value;
  }
  updateStore(name, control) {
    const value = toStore(name, control);
    this.store.update({
      [name]: value
    });
    return value;
  }
  removeInitialValue(name) {
    name ? coerceArray(name).forEach(name => this.initialValues$$.delete(name)) : this.initialValues$$.clear();
  }
  markDescendantsAsDirty(control, options) {
    control.markAsDirty(options);
    if (control instanceof FormGroup || control instanceof FormArray) {
      let controls = Object.keys(control.controls).map(controlName => control.controls[controlName]);
      controls.forEach(control => {
        control.markAsDirty(options);
        if (control.controls) {
          this.markDescendantsAsDirty(control, options);
        }
      });
    }
  }
}
/** @nocollapse */
NgFormsManager.ɵfac = function NgFormsManager_Factory(t) {
  return new (t || NgFormsManager)(i0.ɵɵinject(NG_FORMS_MANAGER_CONFIG, 8), i0.ɵɵinject(FORMS_MANAGER_STORAGE));
};
/** @nocollapse */
NgFormsManager.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgFormsManager,
  factory: NgFormsManager.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgFormsManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: NgFormsManagerConfig,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [NG_FORMS_MANAGER_CONFIG]
      }]
    }, {
      type: Storage,
      decorators: [{
        type: Inject,
        args: [FORMS_MANAGER_STORAGE]
      }]
    }];
  }, null);
})();
function setValidators(control, validator) {
  control.setValidators(coerceArray(validator));
  control.updateValueAndValidity();
}
function setAsyncValidators(control, validator) {
  control.setValidators(coerceArray(validator));
  control.updateValueAndValidity();
}

/**
 * Generated bundle index. Do not edit.
 */

export { FORMS_MANAGER_SESSION_STORAGE_PROVIDER, FORMS_MANAGER_STORAGE, NG_FORMS_MANAGER_CONFIG, NgFormsManager, NgFormsManagerConfig, setAsyncValidators, setValidators };

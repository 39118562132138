<clr-main-container>
    <clr-header class="header-6">
        <div class="branding">
            <a routerLink="/" class="nav-link">
                <i class="mercury-logo">M</i>
                <span class="title">{{'Home' | transloco}}</span>
            </a>
        </div>
        <div class="header-nav">
            <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
               class="nav-link nav-text">{{'Home' | transloco}}</a>
            <a routerLink="/overview" routerLinkActive="active" class="nav-link nav-text">{{'Overview' | transloco}}</a>
            <a routerLink="/chat" routerLinkActive="active" class="nav-link nav-text">{{'Chat' | transloco}}</a>
        </div>
        <form class="search">
            <label for="search_input">
                <input id="search_input" type="text" placeholder="Search for keywords...">
            </label>
        </form>
        <div class="header-actions">
            <app-theme-switcher></app-theme-switcher>
            <app-language-switcher></app-language-switcher>

            <a class="nav-link nav-icon" aria-label="notifications">
                <clr-icon shape="bell" class="has-badge"></clr-icon>
            </a>

            <a class="nav-link nav-icon" aria-label="settings">
                <clr-icon shape="cog"></clr-icon>
            </a>
        </div>
    </clr-header>

    <div class="content-container">
        <clr-vertical-nav [clrVerticalNavCollapsible]="true" [clrVerticalNavCollapsed]="true"
                          class="nav-trigger--bottom">
            <a clrVerticalNavLink routerLink="/" routerLinkActive="active">
                <clr-icon clrVerticalNavIcon shape="user"></clr-icon>
            </a>
            <a clrVerticalNavLink routerLink="/" routerLinkActive="">
                <clr-icon clrVerticalNavIcon shape="cloud"></clr-icon>
            </a>
            <a clrVerticalNavLink routerLink="/" routerLinkActive="">
                <clr-icon clrVerticalNavIcon shape="flag"></clr-icon>
            </a>
        </clr-vertical-nav>


        <nav class="sidenav">
            <section class="sidenav-content">
                <a [routerLink]="['', { outlets: { overlay: ['overlays', 'factsheet'] } }]" class="nav-link">
                    OpenModal 1
                </a>
                <a routerLinkActive="active" class="nav-link">
                    OpenModal 2
                </a>
                <section class="nav-group collapsible">
                    <input id="tabexample1" type="checkbox">
                    <label for="tabexample1">Collapsible Nav Element</label>

                    <ul class="nav-list">
                        <li><a class="nav-link">Link 1</a></li>
                        <li><a class="nav-link">Link 2</a></li>
                    </ul>
                </section>
                <section class="nav-group">
                    <input id="tabexample2" type="checkbox">
                    <label for="tabexample2">Default Nav Element</label>
                    <ul class="nav-list">
                        <li><a class="nav-link">Link 1</a></li>
                        <li><a class="nav-link">Link 2</a></li>
                        <li><a class="nav-link active">Link 3</a></li>
                        <li><a class="nav-link">Link 4</a></li>
                        <li><a class="nav-link">Link 5</a></li>
                        <li><a class="nav-link">Link 6</a></li>
                    </ul>
                </section>
            </section>
        </nav>


        <main class="content-area">
            <ng-content></ng-content>
        </main>
    </div>
</clr-main-container>

<clr-main-container>
    <app-topbar [page]="page"></app-topbar>

    <div class="content-container">
        <app-bar *ngIf="!config.isFeatureActive('disableAppBar')"></app-bar>

        <main class="content-area" [ngClass]="{'unpadded':unpadded}">
            <ng-content></ng-content>
        </main>
    </div>
</clr-main-container>

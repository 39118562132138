<ng-template #template>
    <clr-dropdown>
        <button class="btn btn-link" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="bell" size="24" [ngClass]="{'has-badge': (hasUnreadAlerts$ | ngrxPush)}"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right" class="alert-button__popover">
            <app-alert-panel [alerts]="alerts$ | ngrxPush"></app-alert-panel>
        </clr-dropdown-menu>
    </clr-dropdown>
</ng-template>

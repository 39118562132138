import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { GlobalSearchResultsState, GlobalSearchResultsStore } from './global-search-results.store';
import { LatestGlobalSearchesQuery } from './latest-global-searches.query';
import { combineLatest, Observable } from 'rxjs';
import { createGlobalSearchResult, GlobalSearchResult, GlobalSearchResultType } from './global-search-result.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GlobalSearchResultsQuery extends QueryEntity<GlobalSearchResultsState> {
    selectAllWithLatestSearches$: Observable<GlobalSearchResult[]> = combineLatest([
      this.latestGlobalSearchesQuery.selectAll().pipe(
          map(
              searches => searches.map(
                  search => createGlobalSearchResult({
                    id: search.id,
                    type: GlobalSearchResultType.LATEST
                  })
              )
          )
      ),
      this.selectAll()
    ]).pipe(
        map(([latestGlobalSearchResults, globalSearchResults]) => {
          const latestIds = latestGlobalSearchResults.map(latestGlobalSearchResult => latestGlobalSearchResult.id);

          return [
            ...latestGlobalSearchResults,
            ...globalSearchResults.filter(globalSearchResult => !latestIds.includes(globalSearchResult.id))
          ];
        })
    );

    constructor(
        protected store: GlobalSearchResultsStore,
        private latestGlobalSearchesQuery: LatestGlobalSearchesQuery
    ) {
        super(store);
    }

}

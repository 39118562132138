import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
    transform(value: string, maxLength: number = 16, suffix: string = '...'): string {
        if (value.length > maxLength) {
            return value.substr(0, maxLength) + suffix;
        } else {
            return value;
        }
    }
}

import { Injectable } from '@angular/core';
import { Content, ContentComment, ContentCommentReply, ContentProduct } from './content.model';
import {
    ActiveState,
    applyTransaction,
    arrayAdd,
    arrayUpdate,
    EntityState,
    EntityStore,
    StoreConfig
} from '@datorama/akita';
import { cloneDeep, filter, omit, set } from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';


export interface ContentsState extends EntityState<Content>, ActiveState {
    ui: {
        activeTabId: string;
        showNoMoreMessage: boolean;
        showHasNewContents: boolean;
    };

    filter: string;
    currentSessionId: string;

    contentEditing: {
        currentNewContent: Partial<Content>;
        editContents: { [key: string]: Partial<Content> };
    };

    contentEditorPreviewProduct: ContentProduct;
    contentEditorPreviewError: HttpErrorResponse;

    loadingStates: { [key: string]: boolean };
    opened: Content[];

    loadSingleContentError: { [key: string]: HttpErrorResponse };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'contents' })
export class ContentsStore extends EntityStore<ContentsState> {

    constructor() {
        super({
            filter: '',
            currentSessionId: null,

            contentEditing: {
                currentNewContent: null,
                editContents: {}
            },

            contentEditorPreviewProduct: null,
            contentEditorPreviewError: null,
            opened: [],
            loadSingleContentError: {},
            loadingStates: {},
            ui: {
                activeTabId: '',
                showNoMoreMessage: false,
                showHasNewContents: false
            }
        });
    }

    setLoadingState(path: string | string[], value: boolean) {
        this.update(state => {
            let loadingStates = cloneDeep(state.loadingStates);
            loadingStates = set(loadingStates, path, value);

            return { ...state, loadingStates };
        });
    }

    setCurrentSessionId(sessionId: string) {
        this.update({ currentSessionId: sessionId });
    }

    setLoadSingleContentError(contentId: string, error: HttpErrorResponse) {
        this.update(state => ({
            loadSingleContentError: {
                ...state.loadSingleContentError,
                [contentId]: error
            }
        }));
    }

    setHasNewContents(value: boolean) {
        this.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                showHasNewContents: value
            }
        }));
    }

    setShowNoMoreMessage(value: boolean) {
        this.update(state => ({
            ...state,
            ui: {
                ...state.ui,
                showNoMoreMessage: value
            }
        }));
    }

    replaceContentOpen(id: string) {
        this.update(state => {
            const uiState = { ...state.ui, activeTabId: id };

            return {
                ...state,
                ui: uiState,
                opened: [state.entities[id]]
            };
        });
    }

    openContent(content: Content) {
        this.update(state => {
            const uiState = { ...state.ui };

            return {
                ...state,
                ui: uiState,
                opened: [content]
            };
        });
    }

    updateOpenedContent(id: string, updateFn: (content: Content) => Content) {
        this.update(state => {
            const findOpenedIndex = state.opened.findIndex(c => c.id === id);

            if (findOpenedIndex !== -1) {
                const newEntity = updateFn({
                    ...state.opened[findOpenedIndex] as Content
                });

                const newOpened = state.opened.slice(0);
                newOpened[findOpenedIndex] = newEntity;

                return {
                    ...state,
                    opened: newOpened
                };
            }

            return state;
        });
    }

    addNewCommentForContent(content: Content, comment: ContentComment) {
        this.updateOpenedContent(content.id, entity => {
            return {
                ...entity,
                comments: arrayAdd(entity.comments, comment)
            };
        });
    }

    addNewReplyForComment(content: Content, comment: ContentComment, reply: ContentCommentReply) {
        this.updateOpenedContent(content.id, entity => {
            const findComment = entity.comments.find(c => c.id === comment.id);

            return {
                ...entity,
                comments: arrayUpdate(entity.comments, comment.id, {
                    replies: arrayAdd(findComment.replies, reply)
                })
            };
        });
    }

    updateCommentInContent(content: Content, comment: ContentComment) {
        this.updateOpenedContent(content.id, entity => {
            return {
                ...entity,
                comments: arrayUpdate(entity.comments, comment.id, comment)
            };
        });
    }

    updateReplyForComment(content: Content, comment: ContentComment, reply: ContentCommentReply) {
        this.updateOpenedContent(content.id, entity => {
            const findComment = entity.comments.find(c => c.id === comment.id);

            return {
                ...entity,
                comments: arrayUpdate(entity.comments, comment.id, {
                    replies: arrayUpdate(findComment.replies, reply.id, reply)
                })
            };
        });
    }

    updateNewContent(content: Partial<Content>) {
        this.update(state => ({
            ...state,
            contentEditing: {
                ...state.contentEditing,
                currentNewContent: content ? { ...content } : content
            }
        }));
    }

    updateEditContent(content: Partial<Content>, id: string, addIfNotExists = false) {
        this.update(state => {
            const editContent = state.contentEditing.editContents[id]
                ? { ...state.contentEditing.editContents[id], ...content }
                : addIfNotExists ? { ...content } : null;

            if (!editContent) {
                return state;
            }

            return {
                ...state,
                contentEditing: {
                    ...state.contentEditing,
                    editContents: {
                        ...state.editContents,
                        [id]: editContent
                    }
                }
            };
        });
    }

    removeEditContent(id: string) {
        this.update(state => {
            const editContents = { ...state.contentEditing.editContents };

            return {
                contentEditing: {
                    ...state.contentEditing,
                    editContents: omit(editContents, id)
                }
            };
        });
    }


    removeEditContentAndUpdate(id: string, content: Content) {
        applyTransaction(() => {
            this.update(id, content);
            this.removeEditContent(id);
        });
    }

    // tslint:disable-next-line:no-shadowed-variable
    setFilter(filter: string) {
        this.update({
            filter
        });
    }

    closeOpenedContent(id: string) {
        this.update(state => {
            return {
                ...state,
                opened: filter(state.opened, item => item.id !== id)
            };
        });
    }

    changeSeenState(id: string, state: boolean = true) {
        this.update(id, {
            seen: state
        });
    }

    changeBookmarkState(id: string, state: boolean) {
        this.update(id, {
            bookmarked: state
        });
    }

    updateContentEditorPreviewProduct(product: ContentProduct, error: HttpErrorResponse = null) {
        this.update({
            contentEditorPreviewProduct: product,
            contentEditorPreviewError: error
        });
    }
}


import { Injectable } from '@angular/core';
import { MercuryBrokerApi } from './mercury-broker.api';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';
import { Observable } from 'rxjs';
import { ContentAttachmentExternalLink, ContentProduct } from '../../routes/feeds/state/contents/content.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AttachmentsApi extends MercuryBrokerApi {
    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super('attachments', true, http, configurationService);
    }

    getExternalLinkForAttachment(attachmentId: string): Observable<ContentAttachmentExternalLink> {
        return this.get(attachmentId).pipe(
            map(res => res.data)
        );
    }
}

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ContentAuthor } from '../../../routes/feeds/state/contents/content.model';
import { User } from '../../state/auth/auth.model';

const USER_TO_IMAGE_MAP = {
    '58204ccae4b00b9c1e0ce7c6': '/assets/images/users/58204ccae4b00b9c1e0ce7c6.jpg',
    '5e612d9c9279527187253bab': '/assets/images/users/5e612d9c9279527187253bab.png',
    '5f48bf05652205000168048a': '/assets/images/users/5f48bf05652205000168048a.jpg',
    '5ff3142d6522050001071f06': '/assets/images/users/5ff3142d6522050001071f06.jpg'
};

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnInit, OnChanges {
    @Input()
    author: ContentAuthor & User;

    @ViewChild('template', { static: true }) template;

    @Input()
    size = 50;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private cdf: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.viewContainerRef.createEmbeddedView(this.template);
    }


    get avatarSrc() {
        if (this.author.userProfilePicture) {
            return '/derifiles/' + this.author.userProfilePicture;
        }

        if (this.author.userId && USER_TO_IMAGE_MAP.hasOwnProperty(this.author.userId)) {
            return USER_TO_IMAGE_MAP[this.author.userId];
        }

        return null;
    }

    get name() {
        let name = '';

        if (this.author.firstName) {
            name += this.author.firstName + ' ';
        }

        if (this.author.lastName) {
            name += this.author.lastName;
        }

        if (this.author.username && name === '') {
            name = this.author.username;
        }

        return name;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.author || changes.size) {
            this.cdf.detectChanges();
        }
    }
}

import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { GlobalSearchResultsStore } from './global-search-results.store';
import { createGlobalSearchResult, GlobalSearchResult, GlobalSearchResultType } from './global-search-result.model';
import { delay, map, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Content } from '../../../routes/feeds/state/contents/content.model';
import { LatestGlobalSearchesQuery } from './latest-global-searches.query';



@Injectable({ providedIn: 'root' })
export class GlobalSearchResultsService {

    constructor(
        private globalSearchResultsStore: GlobalSearchResultsStore,
        private latestGlobalSearchesQuery: LatestGlobalSearchesQuery,
        private http: HttpClient
    ) {
    }

    mockData: GlobalSearchResult<any>[] = [
        /*createGlobalSearchResult({ id: 'test search' }),
        createGlobalSearchResult({ id: 'test test' }),
        createGlobalSearchResult({ id: 'testify mock' }),
        createGlobalSearchResult({ id: 'test api' }),
        createGlobalSearchResult({ id: 'testbook' }),
        createGlobalSearchResult({ id: 'test rest api' }),
        createGlobalSearchResult({ id: 'test rest api online' }),
        createGlobalSearchResult({ id: 'testng' }),
        createGlobalSearchResult({ id: 'teste dic' }),
        createGlobalSearchResult({ id: 'tester work' }),
        createGlobalSearchResult({ id: 'testen' }),
        createGlobalSearchResult<Content>({
            id: 'test 1',
            type: GlobalSearchResultType.CONTENT,
            meta: {
                author: {
                    userId: '1',
                    firstName: 'Christoph',
                    lastName: 'Stach',
                    email: 'christoph.stach@gmail.com',
                    username: 'christophstach'
                },
                title: 'Lorem ipsum dolor sit amet. Ein Test',
                bodySummary: 'Lorem ipsum dolor sit amet. Der Test Test.',
                body: {},
            }
        })*/

    ];

    get(query: string): Observable<GlobalSearchResult[]> {
        const queryWords = query.split(' ').filter(word => word.length > 0);
        this.globalSearchResultsStore.setLoading(true);

        return of(this.mockData).pipe(
            map(
                entities => entities.filter(entity => {
                    const entityWords = entity.id.toString().split(' ').filter(word => word.length > 0);

                    return entityWords.some(
                        entityWord => queryWords.some(
                            queryWord => entityWord.includes(queryWord)
                        )
                    );
                })
            ),
            delay(500)
        ).pipe(
            tap(entities => {
                this.globalSearchResultsStore.set(entities);
            })
        );



        /*return this.http.get<GlobalSearchResult[]>('https://api.com').pipe(tap(entities => {
            this.globalSearchResultsStore.set(entities);
        }));*/
    }

    add(globalSearchResult: GlobalSearchResult) {
        this.globalSearchResultsStore.add(globalSearchResult);
    }

    update(id, globalSearchResult: Partial<GlobalSearchResult>) {
        this.globalSearchResultsStore.update(id, globalSearchResult);
    }

    remove(id: string) {
        this.globalSearchResultsStore.remove(id);
    }
}

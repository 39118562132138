<div [class]="'alert-panel-item' + (' alert-panel-item__' + alertInfo.className)"
     [class.alert-panel-item__last]="last"
     [class.alert-panel-item__unread]="alert.unread"
     (click)="onClickAlertAction($event, alert.action)"
     [title]="alert.action && alert.action.label"
>
    <ng-container *ngIf="isSimpleType() else: complexAlert">
        <app-alert-panel-simple-item [alert]="alert"></app-alert-panel-simple-item>
    </ng-container>

    <ng-template #complexAlert>
        <ng-container [ngSwitch]="alert.type">
            <ng-container *ngSwitchCase="AlertType.NEW_CONTENT">
                <app-alert-panel-new-content-item [alert]="alert"></app-alert-panel-new-content-item>
            </ng-container>
        </ng-container>
    </ng-template>

    <button type="button" class="close" aria-label="Close" (click)="onAlertClosedChange(true)">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>
</div>

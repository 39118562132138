import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ContentsStore } from './contents.store';
import {
    Content,
    ContentComment, ContentCommentReply,
    createComment,
    createContent, createErrorContent,
    createReply,
    transformContentDates
} from './content.model';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ContentsQuery } from './contents.query';
import { GroupsQuery } from '../groups';
import { TabManagerService } from '../../../../shared/state/tab-manager/tab-manager.service';
import { TabManagerQuery } from '../../../../shared/state/tab-manager/tab-manager.query';
import { ContentsApi } from './contents.api';
import { TabGroups } from '../../../../shared/state/tab-manager/tab-manager.model';
import { transformContentForServer, transformContentFromServer } from './contents.transform';
import { AuthQuery } from '../../../../shared/state/auth/auth.query';
import { ProductsApi } from '../../../../shared/api/products.api';
import { AttachmentsApi } from '../../../../shared/api/attachments.api';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class ContentsService {

    constructor(
        private contentsStore: ContentsStore,
        private contentsQuery: ContentsQuery,
        private contentsApi: ContentsApi,
        private productsApi: ProductsApi,
        private attachmentsApi: AttachmentsApi,
        private groupsQuery: GroupsQuery,
        private tabManagerService: TabManagerService,
        private tabManagerQuery: TabManagerQuery,
        private authQuery: AuthQuery,
        private translocoService: TranslocoService,
        private http: HttpClient) {
    }


    createSession(group: string, tab: string) {
        return this.contentsApi.createSession(group, tab).pipe(
            map(data => data.sessionId),
            tap(sessionId => {
                this.contentsStore.setCurrentSessionId(sessionId);
            })
        );
    }

    createSearchSession(searchTerm: string) {
        return this.contentsApi.createSearchSession(searchTerm).pipe(
            map(data => data.sessionId),
            tap(sessionId => {
                this.contentsStore.setCurrentSessionId(sessionId);
            })
        );
    }

    get(group?: string, filter?: string, limit = 10) {
        this.contentsStore.setError(null);

        return this.createSession(group, filter).pipe(
            switchMap(session => {
                return this.contentsApi.getContentsFromSession(session, 0, limit).pipe(
                    map(entities => entities.map(transformContentDates))
                );
            }),
            catchError(error => {
                this.contentsStore.setError(error);
                this.contentsStore.setLoading(false);

                return throwError(error);
            })
        );
    }

    getSearchResults(query: string, filter?: string): Observable<Content[]> {
        this.contentsStore.setError(null);

        return this.createSearchSession(query).pipe(
            switchMap(session => {
                return this.contentsApi.getContentsFromSearchSession(session).pipe(
                    map(entities => entities.map(transformContentDates))
                );
            }),
            catchError(error => {
                this.contentsStore.setError(error);
                this.contentsStore.setLoading(false);

                return throwError(error);
            })
        );
    }

    getMore(sessionId: string, group: string, start: number, limit: number) {
        let sessionData$: Observable<any>;

        if (group === 'search') {
            sessionData$ = this.contentsApi.getContentsFromSearchSession(sessionId, start, limit);
        } else {
            sessionData$ = this.contentsApi.getContentsFromSession(sessionId, start, limit);
        }

        this.contentsStore.setError(null);

        return sessionData$.pipe(
            map(entities => {
                return entities.map(entity => ({ ...entity, seen: true }));
            }),
            map(entities => entities.map(transformContentDates)),
            catchError(error => {
                this.contentsStore.setError(error);
                this.contentsStore.setLoading(false);

                return throwError(error);
            })
        );
    }

    getSingleContent(id: string): Observable<Content> {
        const loadingPath = ['CONTENT', 'SINGLE', 'LOADING'];
        this.setLoadingState(loadingPath, true);
        this.contentsStore.setLoadSingleContentError(id, null);

        return this.contentsApi.getContent(id).pipe(
            map(transformContentFromServer),
            catchError(error => {
                this.contentsStore.setLoadSingleContentError(id, error);

                return throwError(error);
            }),
            finalize(() => {
                this.setLoadingState(loadingPath, false);
            }));
    }

    loadCommentsForContent(id: string): Observable<ContentComment[]> {
        const loadingPath = ['CONTENT', 'SINGLE', 'CONTENT', 'LOADING'];
        this.setLoadingState(loadingPath, true);

        return this.contentsApi.getContent(id).pipe(
            map(transformContentFromServer),
            tap(content => {
                this.contentsStore.updateOpenedContent(content.id, c => {
                    return {
                        ...c,
                        comments: content.comments,
                        commentsCount: content.commentsCount,
                        lastReply: content.lastReply
                    };
                });
            }),
            map(content => {
                return content.comments;
            }),
            finalize(() => {
                this.setLoadingState(loadingPath, false);
            })
        );
    }

    load(group?: string, filter?: string, limit = 10, removeMessages = true) {
        this.contentsStore.setLoading(true);
        this.contentsStore.setShowNoMoreMessage(false);
        this.contentsStore.setHasNewContents(false);
        if (removeMessages) {
            this.contentsStore.remove();
        }

        this.get(group, filter)
            .subscribe(entities => {
                this.contentsStore.set(entities);
                this.setGroupViewType(filter);
                this.contentsStore.setShowNoMoreMessage(!entities || !entities.length || entities.length < limit);
            });
    }

    loadSearchResults(query: string, filter?: string, removeMessages = true) {
        this.contentsStore.setLoading(true);
        this.contentsStore.setShowNoMoreMessage(false);
        this.contentsStore.setHasNewContents(false);
        if (removeMessages) {
            this.contentsStore.remove();
        }

        this.getSearchResults(query, filter)
            .subscribe(entities => {
                this.contentsStore.set(entities as Content[]);
                this.setGroupViewType(filter);
                this.contentsStore.setShowNoMoreMessage(!entities || !entities.length);
            });
    }

    loadMore(sessionId: string, start: number, limit: number, group?: string) {
        if (group === 'search') {
            this.contentsStore.setShowNoMoreMessage(true);
            return;
        }

        this.contentsStore.setLoading(true);
        this.contentsStore.setShowNoMoreMessage(false);

        this.getMore(sessionId, group, start, limit)
            .subscribe(entities => {
                const entitiesBefore = this.contentsStore.getValue().entities.length;

                this.contentsStore.add(entities);
                this.contentsStore.setLoading(false);

                const entitiesNow = this.contentsStore.getValue().entities.length;
                const showNoMoreMessage = !entities || !entities.length ||
                    entities.length < limit || entitiesNow === entitiesBefore;

                this.contentsStore.setShowNoMoreMessage(showNoMoreMessage);
            });
    }

    showHasNewContents(value: boolean = false) {
        this.contentsStore.setHasNewContents(value);
    }

    loadSingleContent(id: string) {

    }

    add(partialContent: Partial<Content>) {
        this.setLoadingState(['CONTENT', 'SAVE', 'NEW'], true);

        this.contentsApi.createContent(
            transformContentForServer(partialContent)
        ).pipe(
            map(res => res.data),
            map(transformContentFromServer)
        ).subscribe(content => {
            this.setHasNewContents(true);
            this.setLoadingState(['CONTENT', 'SAVE', 'NEW'], false);

            this.disposeNewContent();
            this.loadContentAndOpen(content.id);
        });
    }

    update(id, updateContent: Partial<Content>) {
        this.setLoadingState(['CONTENT', 'SAVE', id], true);

        this.contentsApi.updateContent(
            transformContentForServer(updateContent)
        ).pipe(
            map(res => res.data),
            map(transformContentFromServer)
        ).subscribe(content => {
            const newContent = { ...content, ...updateContent };
            this.setLoadingState(['CONTENT', 'SAVE', id], false);

            this.disposeContentEditAndUpdate(id, newContent);
            this.updateContentOpen(newContent);

            this.loadContentAndOpen(content.id);
        });
    }

    remove(id: string) {
        const rmPath = ['CONTENT', 'REMOVE'];
        this.setLoadingState(rmPath, true);

        return this.contentsApi.deleteContentById(id).pipe(
            tap(res => {
                this.contentsStore.remove(id);
                this.setLoadingState(rmPath, false);

                if (this.contentsQuery.hasOpenedContent(id)) {
                    this.contentsStore.closeOpenedContent(id);
                }
            })
        );
    }

    findProductInfo(id: string) {
        this.contentsStore.updateContentEditorPreviewProduct(null);

        if (!id || !id.length) {
            return;
        }

        this.setLoadingState(['CONTENT', 'PRODUCT_INFO'], true);

        this.productsApi.findProductInfo(id).subscribe(
            product => {
                this.contentsStore.updateContentEditorPreviewProduct(product);
                this.setLoadingState(['CONTENT', 'PRODUCT_INFO'], false);
            },
            error => {
                this.contentsStore.updateContentEditorPreviewProduct(null, error);
                this.setLoadingState(['CONTENT', 'PRODUCT_INFO'], false);
            }
        );
    }

    setContentActive(id: string) {
        this.contentsStore.setActive(id);
    }

    setHasNewContents(value: boolean) {
        this.contentsStore.setHasNewContents(value);
    }

    setNextContentActive() {
        const allContents = this.contentsQuery.getAll();
        const activeIndex = allContents.indexOf(this.contentsQuery.getActive());

        if (activeIndex !== -1 && activeIndex < allContents.length - 1) {
            this.loadContentAndOpen(allContents[activeIndex + 1].id);
        }
    }

    setPrevContentActive() {
        const allContents = this.contentsQuery.getAll();
        const activeIndex = allContents.indexOf(this.contentsQuery.getActive());

        if (activeIndex !== -1 && activeIndex > 0) {
            this.loadContentAndOpen(allContents[activeIndex - 1].id);
        }
    }

    openContent(id: string) {
        this.contentsStore.replaceContentOpen(id);
        this.setActiveTabId(id);
    }

    updateContentOpen(content: Content) {
        this.contentsStore.openContent(content);
        this.setContentActive(content.id);
    }

    openContentEdit(id: string) {
        if (!this.contentsQuery.isContentWithIdInEdit(id)) {
            let content = null;

            if (this.contentsQuery.hasOpenedContent(id)) {
                content = this.contentsQuery.getOpenedContent(id);
            } else {
                this.getSingleContent(id).subscribe(singleContent => {
                    this.contentsStore.updateEditContent(singleContent, id);
                    this.setActiveTabId('EDIT_' + id);
                });

                return;
            }

            if (content) {
                this.contentsStore.updateEditContent(content, id, true);
            }
        }

        this.setActiveTabId('EDIT_' + id);
    }

    updateContentEdit(content: Partial<Content>, id: string) {
        this.contentsStore.updateEditContent(content, id);
    }

    disposeContentEdit(id: string) {
        this.contentsStore.removeEditContent(id);
        this.disposeActiveTabsId('EDIT_' + id);
    }

    disposeContentEditAndUpdate(id: string, content: Content) {
        this.contentsStore.removeEditContentAndUpdate(id, content);
        this.disposeActiveTabsId('EDIT_' + id);
    }

    setContentActiveAndOpen(id: string) {
        applyTransaction(() => {
            this.openContent(id);
            this.setContentActive(id);
        });
    }

    loadContentAndOpen(id: string) {
        if (this.contentsQuery.isSingleContentLoading()) {
            return;
        }

        if (this.contentsQuery.hasEntity(id)) {
            this.setContentActive(id);
        }

        this.getSingleContent(id).subscribe(content => {
            if (content) {
                this.contentsStore.openContent(content);
                this.setActiveTabId(id);

                if (!content.seen) {
                    this.markContentAsSeen(id);
                }
            }
        }, error => {
            const title = this.translocoService.translate('routes.feeds.components.contentsDetailsPanel.errorPanel.title');
            const contentError: Content = createErrorContent(id, title);

            this.contentsStore.openContent(contentError);
            this.setActiveTabId(id);
        });
    }

    markContentAsSeen(id: string) {
        this.contentsApi.markContentAsSeen(id).subscribe(res => {
            this.contentsStore.changeSeenState(id, true);
        });
    }

    bookmarkContent(id: string, value: boolean) {
        if (value) {
            this.contentsStore.changeBookmarkState(id, true);
            this.contentsStore.updateOpenedContent(id, content => {
                return {
                    ...content,
                    bookmarked: true
                };
            });

            this.contentsApi.addBookmark(id)
                .pipe(
                    catchError((err, caught) => {
                        this.contentsStore.changeBookmarkState(id, false);
                        this.contentsStore.updateOpenedContent(id, content => {
                            return {
                                ...content,
                                bookmarked: false
                            };
                        });

                        return caught;
                    })
                )
                .subscribe();
        } else {
            this.contentsStore.changeBookmarkState(id, false);
            this.contentsStore.updateOpenedContent(id, content => {
                return {
                    ...content,
                    bookmarked: false
                };
            });

            this.contentsApi.removeBookmark(id)
                .pipe(
                    catchError((err, caught) => {
                        this.contentsStore.changeBookmarkState(id, true);
                        this.contentsStore.updateOpenedContent(id, content => {
                            return {
                                ...content,
                                bookmarked: true
                            };
                        });

                        return caught;
                    })
                )
                .subscribe();
        }
    }

    updateNewContent(content: Partial<Content>) {
        this.contentsStore.updateNewContent(content);
    }

    createNewContent() {
        this.setActiveTabId('NEW_CONTENT');

        const firstGroup = this.groupsQuery.getValue().entities?.filter(f => !!f.defaultCategory)[0];
        const activeGroup = this.groupsQuery.getActiveGroup();
        const currentUser = this.authQuery.getCurrentUser();

        this.contentsStore.updateNewContent(createContent({
            categoryId: null,
            notify: this.groupsQuery.getEnabledByDefault(),
        }, currentUser.id));
    }

    disposeNewContent() {
        this.contentsStore.updateNewContent(null);
        this.disposeActiveTabsId('NEW_CONTENT');
    }

    disposeActiveTabsId(id: string) {
        if (this.tabManagerQuery.getActiveTabFromGroup('CONTENTS') === id) {
            const tabs = this.contentsQuery.getOpenedContentTabs();

            this.setActiveTabId(tabs[0]);
        }
    }

    setActiveTabId(id: string) {
        this.tabManagerService.setTabActiveInGroup('CONTENTS', id);
    }

    setLoadingState(path: string | string[], value: boolean) {
        this.contentsStore.setLoadingState(path, value);
    }

    createNewComment(content: Content, body: any) {
        const path = ['CONTENT', 'SAVING', content.id];

        this.setLoadingState(path, true);

        const currentUser = this.authQuery.getCurrentUser();
        const comment = createComment(body, currentUser.id);


        return this.contentsApi.createCommentForContent(
            content, comment
        ).pipe(
            tap(res => {
                this.contentsStore.addNewCommentForContent(content, res.data);
            }),
            finalize(() => this.setLoadingState(path, false))
        );
    }

    createNewReply(content: Content, comment: ContentComment, body: any) {
        const path = ['COMMENT', 'SAVING', comment.id];

        this.setLoadingState(path, true);

        const currentUser = this.authQuery.getCurrentUser();
        const reply = createReply(body, currentUser.id);

        return this.contentsApi.createReplyForComment(
            content, comment, reply
        ).pipe(
            tap(res => {
                this.contentsStore.addNewReplyForComment(content, comment, res.data);
            }),
            finalize(() => this.setLoadingState(path, false))
        );
    }

    updateComment(content: Content, comment: ContentComment) {
        const path = ['COMMENT', 'SAVING', comment.id];
        this.setLoadingState(path, true);

        return this.contentsApi.updateCommentForContent(content.id, comment).pipe(
            tap(res => {
                this.contentsStore.updateCommentInContent(content, res.data);
            }),
            finalize(() => this.setLoadingState(path, false))
        );
    }

    updateReply(content: Content, comment: ContentComment, reply: ContentCommentReply) {
        const path = ['REPLY', 'SAVING', reply.id];

        this.setLoadingState(path, true);

        return this.contentsApi.updateReplyForCommentId(content.id, comment.id, reply).pipe(
            tap(res => {
                this.contentsStore.updateReplyForComment(content, comment, res.data);
            }),
            finalize(() => this.setLoadingState(path, false))
        );

    }

    setGroupViewType(id: string) {
        this.contentsStore.setFilter(id as string);
        this.tabManagerService.setTabActiveInGroup(TabGroups.GROUPINGS, id);
    }

    getExternalLinkForAttachment(attachmentId: string) {
        const loadingPath = ['ATTACHMENT', 'EXTERNAL', attachmentId];
        this.setLoadingState(loadingPath, true);

        return this.attachmentsApi.getExternalLinkForAttachment(attachmentId).pipe(
            finalize(() => this.setLoadingState(loadingPath, false))
        );
    }
}

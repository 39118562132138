import { QueryEntity } from '@datorama/akita';
import { AlertsState, AlertsStore } from './alerts.store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AlertsQuery extends QueryEntity<AlertsState> {
    hasUnreadAlerts$: Observable<boolean> = this.selectAll({
        filterBy: notification => notification.unread
    }).pipe(
        map(notification => notification.length > 0)
    );

    mutedGroupings$: Observable<{ [key: string]: boolean }> = this.select(state => state.ui.mutedGroupings);
    muteNotificationSounds$: Observable<boolean> = this.select(state => state.ui.muteNotificationSounds);

    constructor(protected store: AlertsStore) {
        super(store);
    }

    hasNativeNotificationsPermission() {
        return this.getValue().ui.nativeNotificationsPermitted;
    }

    isNotificationSoundsMuted() {
        return this.getValue().ui.muteNotificationSounds;
    }
}

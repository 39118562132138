import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ReviewState } from "../state/review.state";

export const selectReview = createFeatureSelector<ReviewState>('review');

export const selectList = createSelector(
    selectReview,
    (state: ReviewState) => state.list
);

export const selectListIds = createSelector(
    selectReview,
    (state: ReviewState) => state.list.map(content => content.id)
);

<div class="auth-panel-user">
	<div class="auth-panel-user__avatar">
		<app-user-avatar [author]="user"></app-user-avatar>
	</div>
	<div class="auth-panel-user__content">
		<div class="auth-panel-user__content__name">
			{{ user.firstName }} {{ user.lastName }}
		</div>
		<div class="auth-panel-user__content__change-picture">
			<a [href]="profilePictureChangeUrl" target="_blank">{{'shared.components.authPanelUser.changePicture' | transloco}}</a>
		</div>
	</div>
</div>

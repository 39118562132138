<form class="search-form" [formGroup]="globalSearchForm">
    <label for="search-input">
        <clr-icon shape="search"></clr-icon>
    </label>

    <input
        clrInput
        #autoCompleteInput
        [appAutoComplete]="autoComplete"
        [formControl]="globalSearchFormControl"
        (selectValue)="onOptionSelect($event)"
        (submitValue)="handleInputSubmit($event)"
        formControlName="query"
        id="search-input"
        [placeholder]="'shared.components.globalSearchBar.searchInputPlaceholder' | transloco"
        type="text"
        autocomplete="off"
        spellcheck="false"
        required>

    <ng-container *ngIf="(globalSearchResultsLoading$ | ngrxPush); else invisibleSpinner">
        <span class="spinner spinner-inline spinner-inverse">
            {{'shared.components.globalSearchBar.loading' | transloco}}
        </span>
    </ng-container>
    <ng-template #invisibleSpinner>
        <a href="#" class="deleter"
           *ngIf="this.globalSearchForm?.value?.query"
           (click)="onClickClearSearch($event)">
            <clr-icon shape="times" class="is-inverse" size="20"></clr-icon>
        </a>
    </ng-template>
</form>

<app-auto-complete #autoComplete="appAutoComplete">
    <ng-template appAutoCompleteContent>
        <ng-container *ngFor="let result of (globalSearchResults$ | ngrxPush); let first = first; let last = last;">
            <app-auto-complete-option
                [first]="first"
                [last]="last"
                [value]="result.id">

                <ng-container [ngSwitch]="result.type">
                    <div *ngSwitchCase="GlobalSearchResultType.LATEST"
                         class="auto-complete-option__inner auto-complete-option__inner--latest">
                        <div class="auto-complete-option__inner__icon">
                            <clr-icon shape="clock"></clr-icon>
                        </div>
                        <div class="auto-complete-option__inner__text"
                             [innerHTML]="result.id | multiHighlight: (globalSearchValues$ | ngrxPush)?.query"></div>
                        <div class="auto-complete-option__inner_remove">
                            <a href="#"
                               (click)="onRemoveLatestGlobalSearchClick($event, result.id)">{{'shared.components.globalSearchBar.removeFromLatestSearch' | transloco}}</a>
                        </div>
                    </div>
                    <div *ngSwitchCase="GlobalSearchResultType.CONTENT"
                         class="auto-complete-option__inner auto-complete-option__inner--content">
                        <div class="auto-complete-option__inner__icon">
                            <clr-icon shape="file-share"></clr-icon>
                        </div>
                        <div class="auto-complete-option__inner__text">
                            <h6 [innerHTML]="result.meta?.title | multiHighlight: (globalSearchValues$ | ngrxPush)?.query"></h6>
                            <div
                                [innerHTML]="result.meta?.short | multiHighlight: (globalSearchValues$ | ngrxPush)?.query"></div>
                        </div>
                    </div>
                    <div *ngSwitchDefault class="auto-complete-option__inner">
                        <div class="auto-complete-option__inner__icon">
                            <clr-icon shape="search"></clr-icon>
                        </div>
                        <div class="auto-complete-option__inner__text"
                             [innerHTML]="result.id | multiHighlight: (globalSearchValues$ | ngrxPush)?.query"></div>
                    </div>
                </ng-container>

            </app-auto-complete-option>
        </ng-container>
    </ng-template>
</app-auto-complete>

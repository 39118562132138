import { Injectable } from '@angular/core';
import { Auth, AuthToken, User } from './auth.model';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';


export interface AuthState {
    user: User | null;
    jwt: AuthToken;
}

export const initialState: AuthState = {
    user: null,
    jwt: null
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {

    constructor() {
        super(initialState);
    }

    updateAuthToken(token: AuthToken) {
        this.update((state) => {
            return {
                ...state,
                jwt: {
                    ...(state.jwt ? state.jwt : {}),
                    ...token
                }
            };
        });
    }

    updateUser(user: User) {
        this.update((state) => {
            return {
                ...state,
                user
            };
        });
    }

    resetAuthToken() {
        this.update((state) => {
            return {
                ...state,
                jwt: {
                    ...state.jwt,
                    token: null
                }
            };
        });
    }
}


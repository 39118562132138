import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SplitViewService {
    categoriesListWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
    contentsListWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
    contentsDetailsWidth$: BehaviorSubject<number> = new BehaviorSubject(0);

    constructor() {
    }

    set categoriesListWidth(width: number) {
        this.categoriesListWidth$.next(width);
    }

    get categoriesListWidth() {
        return this.categoriesListWidth$.getValue();
    }

    set contentsListWidth(width: number) {
        this.contentsListWidth$.next(width);
    }

    get contentsListWidth() {
        return this.contentsListWidth$.getValue();
    }

    set contentsDetailsWidth(width: number) {
        this.contentsDetailsWidth$.next(width);
    }

    get contentsDetailsWidth() {
        return this.contentsDetailsWidth$.getValue();
    }
}

import { Injectable } from '@angular/core';
import { GlobalSearchResult } from './global-search-result.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface GlobalSearchResultsState extends EntityState<GlobalSearchResult> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'global-search-results' })
export class GlobalSearchResultsStore extends EntityStore<GlobalSearchResultsState> {

  constructor() {
    super();
  }

}

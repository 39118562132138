import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { mapTo } from 'rxjs/operators';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-auto-complete-option',
    templateUrl: './auto-complete-option.component.html',
    styleUrls: ['./auto-complete-option.component.scss']
})
export class AutoCompleteOptionComponent implements OnInit {
    @Input() first = false;
    @Input() last = false;
    @Input() active = false;
    @Input() value: string;

    click$: Observable<AutoCompleteOptionComponent>;
    mouseEnter$: Observable<AutoCompleteOptionComponent>;
    mouseLeave$: Observable<AutoCompleteOptionComponent>;
    subscription: Subscription;

    constructor(private host: ElementRef) {
    }

    ngOnInit() {
        this.click$ = fromEvent<MouseEvent>(this.element, 'click').pipe(mapTo(this));
        this.mouseEnter$ = fromEvent<KeyboardEvent>(this.element, 'mouseenter').pipe(mapTo(this));
        this.mouseLeave$ = fromEvent<KeyboardEvent>(this.element, 'mouseleave').pipe(mapTo(this));
    }

    get element() {
        return this.host.nativeElement;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safeResourceUrl'
})
export class SafeResourceUrlPipe implements PipeTransform {
    constructor(protected _sanitizer: DomSanitizer) {
    }

    transform(value: string): SafeResourceUrl {
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    }
}

<clr-main-container>
    <clr-header>
        <div class="branding">
            <a routerLink="/" class="nav-link">
                <clr-icon shape="vm-bug"></clr-icon>
                <span class="title">{{'Contents' | transloco}}</span>
            </a>
        </div>
        <div class="header-nav">
            <a routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
               class="nav-link nav-text">{{'Home' | transloco}}</a>
            <a routerLink="/overview" routerLinkActive="active" class="nav-link nav-text">{{'Overview' | transloco}}</a>
            <a routerLink="/chat" routerLinkActive="active" class="nav-link nav-text">{{'Chat' | transloco}}</a>
        </div>
        <div class="header-actions">
            <app-theme-switcher></app-theme-switcher>
            <app-language-switcher></app-language-switcher>

            <a href="javascript://" class="nav-link nav-icon" aria-label="settings">
                <clr-icon shape="cog"></clr-icon>
            </a>
        </div>
    </clr-header>

    <div class="content-container">
        <app-bar></app-bar>

        <main class="content-area">
            <ng-content></ng-content>
        </main>
    </div>
</clr-main-container>

import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import classNames from 'classnames';
import { ClrLoadingState, LoadingListener } from '@clr/angular';

@Component({
    selector: 'app-button',
    template: `
        <button
                [class]="buttonClasses"
                (click)="handleClick($event)"
                [attr.type]="type"
                [attr.name]="name"
                [attr.disabled]="disabled"
                [id]="id"
                [clrLoading]="loadingState">
            <clr-icon [class.start]="true" [class.is-solid]="iconSolid" [attr.shape]="icon"
                      *ngIf="icon && iconPosition === 'start'"></clr-icon>
            <ng-content *ngIf="!onlyIcon"></ng-content>
            <span class="spinner spinner-inline" *ngIf="loading"></span>
            <clr-icon [class.end]="true" [class.is-solid]="iconSolid" [attr.shape]="icon" *ngIf="icon && iconPosition === 'end'"></clr-icon>
        </button>
    `,
    providers: [{ provide: LoadingListener, useExisting: ButtonComponent }]
})
export class ButtonComponent implements OnInit, AfterViewInit {

    get id(): string {
        return this._id;
    }

    @Input('id')
    set id(value: string) {
        if (typeof value === 'string') {
            this._id = value;
        }
    }

    get name(): string {
        return this._name;
    }

    @Input('name')
    set name(value: string) {
        if (typeof value === 'string') {
            this._name = value;
        }
    }

    get type(): string {
        return this._type;
    }

    @Input('type')
    set type(value: string) {
        if (typeof value === 'string') {
            this._type = value;
        }
    }

    // @HostBinding('class')
    get buttonClasses(): string {
        const classes = {
            btn: true,
            disabled: this.disabled,
            'btn-link': this.link,
            'btn-sm': this.small,
            'btn-outline': this.outline,
            'btn-inverse': this.inverse,
            'btn-icon': !!this.icon,
            'btn-only-icon': this.onlyIcon
        };

        if (this.level) {
            let levelClass = 'btn-' + this.level;

            if (this.outline) {
                levelClass += '-outline';
            }

            classes[levelClass] = true;
        }

        return classNames(classes);
    }

    constructor() {
    }

    public loading: boolean;
    private _enableService = false;

    @Output()
    clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    @Input()
    icon: string = null;

    @Input()
    iconPosition: 'start' | 'end' = 'start';

    @Input()
    level: 'primary' | 'warning' | 'danger' | 'success' = null;

    @Input()
    iconSolid = false;

    @Input()
    link = false;

    @Input()
    outline = false;

    @Input()
    small = false;

    @Input()
    inverse = false;

    @Input()
    disabled = false;

    @Input()
    loadingState = null;

    @Input()
    onlyIcon = false;

    private _id: string = null;

    private _name: string = null;

    private _type: string = null;

    handleClick(event) {
        this.clicked.emit(event);
    }

    ngOnInit() {
    }


    loadingStateChange(state: ClrLoadingState): void {
        this.loading = state === ClrLoadingState.LOADING;
    }

    ngAfterViewInit() {
        this._enableService = true;
    }

}

import { Component, OnInit } from '@angular/core';
import { ApplicationViewQuery } from '../../state/application-view/application-view.query';
import { AuthQuery } from '../../state/auth/auth.query';
import { AuthService } from '../../state/auth/auth.service';
import { runOnce } from '../../helpers/observable.helper';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-topbar-auth-panel',
    templateUrl: './topbar-auth-panel.component.html',
    host: { '[class.top-bar__auth-panel]': 'true' }
})
export class TopbarAuthPanelComponent implements OnInit {
    isLoggedIn$ = this.authQuery.isLoggedIn$;
    currentUser$ = this.authQuery.currentUser$;

    constructor(
        private applicationViewQuery: ApplicationViewQuery,
        private authService: AuthService,
        private authQuery: AuthQuery,
        public config: ConfigurationService
    ) {
    }

    ngOnInit() {
    }

    handleClickSignOut(event: MouseEvent) {
        event.preventDefault();

        runOnce(this.authService.logout());
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { ApplicationViewQuery } from '../../state/application-view/application-view.query';
import { AuthQuery } from '../../state/auth/auth.query';
import { ContentsService } from '../../../routes/feeds/state/contents/contents.service';
import { ConfigurationService } from '../../services/configuration.service';
import { map } from 'rxjs/operators';
import { SplitViewService } from '../../services/split-view.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ReviewSelectors from '../../../routes/review/store/selectors/review.selectors';
import { Page } from './topbar.interface';
import { Content } from '../../../routes/feeds/state/contents/content.model';
import { GroupsQuery, HiddenFeature } from '../../../routes/feeds/state/groups';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    host: { '[class.top-bar]': 'true' }
})
export class TopbarComponent implements OnInit {

   @Input() page: Page; 

    groupingListWidth$ = this.splitViewService.categoriesListWidth$.pipe(
        map(width => {
            return width < 50 ? 350 : width;
        })
    );
    currentUser$ = this.authQuery.currentUser$;
    
    reviewList$: Observable<Array<Content>>;

    constructor(
        private applicationViewQuery: ApplicationViewQuery,
        private splitViewService: SplitViewService,
        private authQuery: AuthQuery,
        private contentsService: ContentsService,
        public config: ConfigurationService,
        private store: Store,
        private groupsQuery: GroupsQuery
    ) {
    }

    ngOnInit() {
        this.reviewList$ = this.store.select(ReviewSelectors.selectList);
    }

    onClickContentAdd($event: MouseEvent) {
        $event.preventDefault();

        this.contentsService.createNewContent();
    }

    get hasEnvironmentLogo() {
        return !!this.config.value.logoInverse;
    }

    get logoPath() {
        return ['assets', 'images', 'logos', this.config.value.logoInverse].join('/');
    }

    get isNewsletterReviewHidden(): boolean {
        return this.groupsQuery.isFeatureHidden(HiddenFeature.NEWSLETTER_REVIEW);
    }
}

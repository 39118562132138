import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { Alert, AlertAction, AlertType } from '../../state/alerts/alert.model';
import { ClrDropdown } from '@clr/angular';
import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';

export const alertTypeMap = {
    [AlertType.WARNING]: { className: 'warning', icon: 'warning-standard' },
    [AlertType.INFO]: { className: 'info', icon: 'help-info' },
    [AlertType.NEW_MUST_READ]: { className: 'info', icon: 'help-info' },
    [AlertType.SUCCESS]: { className: 'success', icon: 'success-standard' },
    [AlertType.ERROR]: { className: 'danger', icon: 'danger-standard' }
};

@Component({
    selector: 'app-alert-panel-item',
    templateUrl: './alert-panel-item.component.html'
})
export class AlertPanelItemComponent implements OnInit {
    @Input()
    alert: Alert;

    @Input()
    last = false;

    @Output()
    alertClosed: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    @Output()
    alertActionClicked: EventEmitter<AlertAction> = new EventEmitter<AlertAction>();

    AlertType = AlertType;

    constructor() {
    }

    ngOnInit() {
    }


    get alertInfo(): { className: string, icon: string } {
        return alertTypeMap[this.alert.type] || {};
    }

    onAlertClosedChange(state: boolean) {
        this.alertClosed.emit(state);
    }

    isSimpleType() {
        const alertType = this.alert.type;
        const simpleType = [AlertType.NEW_MUST_READ, AlertType.ERROR, AlertType.INFO, AlertType.SUCCESS, AlertType.WARNING];

        return simpleType.indexOf(alertType) !== -1;
    }

    onClickAlertAction(event: MouseEvent, action: AlertAction) {
        event.preventDefault();

        if (action) { this.alertActionClicked.emit(action); }
    }
}

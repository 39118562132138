import { CommentModel, Content, ContentAttachment, ContentComment, ContentProduct } from './content.model';
import { isArray, isString, omit, without } from 'lodash-es';

export function transformCommentToRequestBody(comment: CommentModel) {
    return {
        id: comment.id,
        body: comment.body,
        author: comment.author?.userId,
        revisionAuthor: comment.revisionAuthor?.userId || comment.author?.userId
    };
    /*return {
        ...omit(comment, 'replies', 'updatedAt'),
        author: comment.author?.userId,
        revisionAuthor: comment.revisionAuthor?.userId || comment.author?.userId
    };*/
}

export function getDynamicQueryParams(offset?: number, limit?: number) {
    return {
        limit: limit ? limit.toString() : '',
        offset: offset ? offset.toString() : ''
    };
}

export function transformOpToQuill(op: any) {
    if (op.attributes && op.attributes.embed) {
        const embed = op.attributes.embed;
        const restAttributes = omit(op.attributes, 'embed');

        switch (embed.type) {
            case 'image':
                return {
                    insert: {
                        image: embed.source
                    },
                    attributes: {
                        ...restAttributes
                    }
                };
            case 'video':
                return {
                    insert: {
                        video: embed.source
                    },
                    attributes: {
                        ...restAttributes
                    }
                };
            case 'hr':
                return {
                    insert: {
                        divider: true
                    },
                    attributes: {
                        ...restAttributes
                    }
                };
        }
    }

    return op;
}

function makeOpEmbed(op: any, key: string, noSource = false) {
    const insert = op.insert;
    const opAttributes = op.attributes ? op.attributes : {};

    return {
        insert: '',
        attributes: {
            embed: {
                type: key,
                ...(!noSource ? { source: insert[key] } : {})
            },
            ...opAttributes
        }
    };
}

export function transformOpFromQuill(op: any) {
    if (!isString(op.insert)) {
        const insert = op.insert;

        if (insert.image) {
            return makeOpEmbed(op, 'image');
        } else if (insert.video) {
            return makeOpEmbed(op, 'video');
        } else if (insert.divider) {
            return makeOpEmbed(op, 'hr', true);
        }
    }

    return op;
}

export function transformToQuillDelta(data: any) {
    if (data && data.ops && isArray(data.ops)) {
        return { ops: data.ops.map(transformOpToQuill) };
    } else if (data && isArray(data)) {
        return data.map(transformOpToQuill);
    }

    return {};
}

export function transformFromQillDelta(data: any) {
    if (data && data.ops && isArray(data.ops)) {
        return { ops: data.ops.map(transformOpFromQuill) };
    } else if (data && isArray(data)) {
        return data.map(transformOpFromQuill);
    }

    return {};
}

export function transformContentFromServer(content: Content) {
    let body = content.body;

    if (isString(body)) {
        body = JSON.parse(body);
    }
    return {
        ...content, body: transformToQuillDelta(body)
    };
}

export function transformContentForServer(content: Partial<Content>) {
    const attachments = content.attachments as ContentAttachment[] || [];
    const products = content.products as ContentProduct[] || [];

    return {
        ...omit(content, ['bodySummary', 'author', 'comments']),
        body: transformFromQillDelta(content.body),
        authorId: content.authorId || content.author.userId,
        attachments: attachments.map(a => a.id)
    };
}

export function transformCommentForRequest(comment: Partial<CommentModel>): Partial<CommentModel> {
    return comment;
}

import { Component, Input, OnInit } from '@angular/core';
import { NewContentAlert } from '../../state/alerts/alert.model';
import { alertTypeMap } from './alert-panel-item.component';


@Component({
    selector: 'app-alert-panel-new-content-item',
    template: `
        <div class="alert-panel-item__image">
            <ngx-avatars size="30" [src]="" [name]="name" [round]="true"></ngx-avatars>
        </div>

        <div class="alert-panel-item__content">
            <strong><a href="#">{{alert.extra.author}}</a> added a new content</strong><br>
            {{alert.text}}
        </div>
    `
})
export class AlertPanelNewContentItemComponent implements OnInit {
    @Input()
    alert: NewContentAlert;

    constructor() {
    }

    get alertInfo() {
        return alertTypeMap[this.alert.type];
    }

    get name() {
        let name = '';

        if (this.alert.extra.author) {
            name += this.alert.extra.author;
        }

        return name;
    }


    ngOnInit() {
    }
}

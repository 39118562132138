import { v4 as uuid } from 'uuid';

export enum AlertType {
    ERROR,
    WARNING,
    INFO,
    SUCCESS,

    NEW_CONTENT,
    NEW_MUST_READ
}

export enum AlertActionType {
    NONE,
    OPEN_CHAT_MESSAGE,
    OPEN_GROUP
}

export interface OpenGroupActionExtra {
    id: string;
}

export interface AlertAction<E = any> {
    type: AlertActionType;
    label: string;
    translate?: boolean;
    extra: E;
}

export interface Alert<E = any, A = any> {
    id: string;
    title: string;
    text: string;
    translateTitle?: boolean;
    translateText?: boolean;
    translateTitleParams?: { [key: string]: any; };
    translateTextParams?: { [key: string]: any; };
    type: AlertType;
    action: AlertAction<A>;
    unread: boolean;
    date: Date;
    extra?: E;
}

export interface NewContentExtra {
    author: string;
    authorId: string;
    contentId: string;
}

export interface NewMustReadExtra {
    groups: { id: string, label: string }[];
    newMustReadCount: number;
}

export interface NewContentAlert extends Alert<NewContentExtra> {
}

export interface NewMustReadAlert extends Alert<NewMustReadExtra, OpenGroupActionExtra> {
}

export function createAlert(params: Partial<Alert>) {
    const { type, action, unread, date } = params;

    return {
        ...params,
        type: type !== null ? type : AlertType.INFO,
        action: action !== null ? action : null,
        unread: unread !== null ? unread : true,
        date: date ? date : new Date()
    } as Alert;
}

export function createNewContentAlert(params: Partial<NewContentAlert>) {
    return createAlert({
        ...params,
        type: AlertType.NEW_CONTENT
    });
}

export function createNewMustReadAlert(params: Partial<NewMustReadAlert>) {
    let text: string;

    if (params.extra.newMustReadCount === 0) {
        text = 'alerts.newMustRead.noMandatoryContent';
    } else if (params.extra.newMustReadCount === 1) {
        text = 'alerts.newMustRead.oneMandatoryContent';
    } else {
        text = 'alerts.newMustRead.xMandatoryContents';
    }

    return createAlert({
        id: uuid(),
        ...params,
        type: AlertType.NEW_MUST_READ,
        title: 'alerts.newMustRead.title',
        text,
        translateText: true,
        translateTitle: true,
        translateTextParams: {
            count: params.extra.newMustReadCount,
            groups: params.extra.groups.map(g => g.label).join(', ')
        },
        translateTitleParams: {
            count: params.extra.newMustReadCount
        },
        unread: true
    });
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLayoutComponent } from './layouts/chat-layout/chat-layout.component';
import { ContentsLayoutComponent } from './layouts/contents-layout/contents-layout.component';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ThemeSwitcherComponent } from './components/theme-switcher/theme-switcher.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { AppbarComponent } from './components/appbar/appbar.component';
import { TopbarAuthPanelComponent } from './components/topbar-auth-panel/topbar-auth-panel.component';
import { GlobalSearchBarComponent } from './components/global-search-bar/global-search-bar.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { AuthResolver } from './state/auth/auth.resolver';
import { RadioButtonGroupComponent } from './components/radio-button-group/radio-button-group.component';
import { RadioButtonGroupButtonComponent } from './components/radio-button-group/radio-button-group-button.component';
import { AvatarModule } from 'ngx-avatars';
import { NgxFilesizeModule } from 'ngx-filesize';
import { AlertButtonComponent } from './components/alert-button/alert-button.component';
import { QuillModule } from 'ngx-quill';
import { ContenteditableModelDirective } from './directives/contenteditable-model.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertPanelComponent } from './components/alert-panel/alert-panel.component';
import { AlertPanelItemComponent } from './components/alert-panel-item/alert-panel-item.component';
import { AuthPanelUserComponent } from './components/auth-panel-user/auth-panel-user.component';
import { AlertPanelSimpleItemComponent } from './components/alert-panel-item/alert-panel-simple-item';
import { AlertPanelNewContentItemComponent } from './components/alert-panel-item/alert-panel-new-content-item';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AppOverlayScrollbarsComponent } from './components/overlay-scrollbars/app-overlay-scrollbars.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabContentComponent } from './components/tabs/tab-content.component';
import { TabLinkDirective } from './components/tabs/tab-link.directive';
import { TabTemplateRefComponent } from './components/tabs/tab-template-ref.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { AutoCompleteOptionComponent } from './components/auto-complete-option/auto-complete-option.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { AutoCompleteDirective } from './directives/auto-complete.directive';
import { AutoCompleteContentDirective } from './directives/auto-complete-content.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MultiHighlightPipe } from './pipes/multi-highlight.pipe';
import { ButtonComponent } from './components/button/button.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { OverlayComponent } from './components/overlay/overlay.component';
import { TopbarSettingsPanelComponent } from './components/topbar-settings-panel/topbar-settings-panel.component';
import { DevToolbarComponent } from './components/dev-toolbar/dev-toolbar.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { LetDirective, PushPipe } from '@ngrx/component';
import { SafeResourceUrlPipe } from './pipes/safe-resource-url.pipe';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

@NgModule({
    declarations: [
        ChatLayoutComponent,
        ContentsLayoutComponent,
        SidebarComponent,
        ThemeSwitcherComponent,
        LanguageSwitcherComponent,
        DefaultLayoutComponent,
        TopbarComponent,
        TopbarAuthPanelComponent,
        AppbarComponent,
        GlobalSearchBarComponent,
        LoginLayoutComponent,
        RadioButtonGroupComponent,
        RadioButtonGroupButtonComponent,
        AlertButtonComponent,
        ContenteditableModelDirective,
        AlertPanelComponent,
        AlertPanelItemComponent,
        AlertPanelSimpleItemComponent,
        AlertPanelNewContentItemComponent,
        AuthPanelUserComponent,
        AppOverlayScrollbarsComponent,
        TabsComponent,
        TabComponent,
        TabContentComponent,
        TabLinkDirective,
        TabTemplateRefComponent,
        ButtonComponent,
        TruncatePipe,
        AutoCompleteOptionComponent,
        AutoCompleteComponent,
        AutoCompleteDirective,
        AutoCompleteContentDirective,
        MultiHighlightPipe,
        OverlayComponent,
        TopbarSettingsPanelComponent,
        DevToolbarComponent,
        SafeResourceUrlPipe,
        UserAvatarComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ClarityModule,
        AvatarModule,
        NgxFilesizeModule,
        InlineSVGModule.forRoot(),
        QuillModule.forRoot({
            formats: [
                /* inline */
                'bold', 'code', 'italic', 'link', 'strike', 'script', 'underline',
                /* block */
                'blockquote', 'header', 'list', 'align', 'code-block',
                /* embeds */
                'formula', 'image', 'video',
                /* custom */
                'divider', 'ltmatch'
            ]
        }),
        FormsModule,
        ReactiveFormsModule,
        DropzoneModule,
        NgxSkeletonLoaderModule,
        VirtualScrollerModule,
        OverlayModule,
        TranslocoModule,
        NgxExtendedPdfViewerModule,
        LetDirective,
        PushPipe
    ],
    providers: [AuthResolver],
    exports: [
        CommonModule,
        RouterModule,
        ClarityModule,
        QuillModule,
        InlineSVGModule,
        FormsModule,
        ReactiveFormsModule,
        DropzoneModule,
        NgxSkeletonLoaderModule,
        VirtualScrollerModule,
        OverlayModule,
        TranslocoModule,
        NgxExtendedPdfViewerModule,
        TranslocoLocaleModule,
        OverlayComponent,
        ContentsLayoutComponent,
        ChatLayoutComponent,
        DefaultLayoutComponent,
        ThemeSwitcherComponent,
        LanguageSwitcherComponent,
        TopbarComponent,
        TopbarAuthPanelComponent,
        GlobalSearchBarComponent,
        RadioButtonGroupComponent,
        RadioButtonGroupButtonComponent,
        ContenteditableModelDirective,
        AppOverlayScrollbarsComponent,
        UserAvatarComponent,
        TabsComponent,
        TabComponent,
        TabContentComponent,
        TabLinkDirective,
        TabTemplateRefComponent,
        TruncatePipe,
        AutoCompleteOptionComponent,
        AutoCompleteComponent,
        AutoCompleteDirective,
        AutoCompleteContentDirective,
        ButtonComponent,
        DevToolbarComponent,
        LetDirective,
        PushPipe,
        SafeResourceUrlPipe
    ]
})
export class SharedModule {
}

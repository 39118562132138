import { Injectable } from '@angular/core';
import { Group, GroupList, GroupMetaResponse, GroupWrapper } from './group.model';
import { ActiveState, EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';

export interface CategoriesState {
    filter: string;

    activeGroup: string;
    groups: GroupWrapper;
    contentCreationGroups: GroupWrapper;
    entities: GroupList;
    groupsMeta: GroupMetaResponse;
    flashGroups: string[];
}


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'groups', resettable: true })
export class GroupsStore extends Store<CategoriesState> {

    constructor() {
        super({
            filter: 'ALL',
            activeGroup: null,
            groups: null,
            contentCreationGroups: null,
            entities: [],
            groupsMeta: {},
            flashGroups: []
        });
    }

    setActiveGroup(activeGroup: string) {
        this.update({
            activeGroup
        });
    }

    setGroups(groups: GroupWrapper) {
        this.update({
            groups
        });
    }

    setGroupEntities(entities: GroupList) {
        this.update({
            entities
        });
    }

    setContentCreationGroups(groups: GroupWrapper) {
        this.update({
            contentCreationGroups: groups,
        });
    }

    updateNavigationSeenMeta(metaData: GroupMetaResponse) {
        this.update({
            groupsMeta: metaData
        });
    }

    updateFlashGroups(flashGroups: string[]) {
        this.update({
            flashGroups
        });
    }

    resetNavigationSeenMeta() {
        this.update({
            groupsMeta: {}
        });
    }
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'multiHighlight'
})
export class MultiHighlightPipe implements PipeTransform {

    transform(value: string, arg: string): string {
        if (!arg.trim()) {
            return value;
        }

        const words = arg.split(' ').filter(word => word.length > 0);

        words.forEach(word => {
            try {
                const regex = new RegExp(`(${escape(word)})`, 'i');
                value = value.replace(regex, '<b>$1</b>');
            } catch (e) {
            }
        });

        return value;
    }

}

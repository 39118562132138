import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-radio-button-group-button',
    template: `
        <ng-template #buttonRef>
            <div class="radio btn">
                <input type="radio" [attr.name]="name" [attr.id]="id">
                <label [for]="id">
                    <ng-content></ng-content>
                </label>
            </div>
        </ng-template>
    `,
})
export class RadioButtonGroupButtonComponent {
    private _name: string = null;

    @ViewChild('buttonRef', { static: true })
    templateRef: TemplateRef<RadioButtonGroupButtonComponent>;

    get name(): string {
        return this._name;
    }

    @Input('name')
    set name(value: string) {
        if (typeof value === 'string') {
            this._name = value;
        }
    }

    private _id: string = null;

    get id(): string {
        return this._id;
    }

    @Input('id')
    set id(value: string) {
        if (typeof value === 'string') {
            this._id = value;
        }
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationViewService } from '../../state/application-view/application-view.service';
import { ApplicationViewQuery } from '../../state/application-view/application-view.query';
import { ApplicationView } from '../../state/application-view/application-view.model';
@Component({
  selector: 'app-bar',
  templateUrl: './appbar.component.html',
  host: { '[class.app-bar]': 'true', '[class.os-host-flexbox]': 'true' },
})
export class AppbarComponent implements OnInit {
  @Input() items: ApplicationView[];
  @Input() selectedItemId: string;

  items$: Observable<ApplicationView[]>;

  constructor(
      private readonly applicationViews: ApplicationViewService,
      private readonly applicationViewsQuery: ApplicationViewQuery
  ) {
    this.items$ = applicationViewsQuery.selectAll();
  }

  ngOnInit() {
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../state/auth/auth.model';
import { getAvatarNameForAuthor } from '../../../routes/feeds/helpers/author.helper';
import { ContentAuthor } from '../../../routes/feeds/state/contents/content.model';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-auth-panel-user',
    templateUrl: './auth-panel-user.component.html'
})
export class AuthPanelUserComponent implements OnInit {

    @Input()
    user: User;

    constructor(private configuration: ConfigurationService) {
    }

    ngOnInit() {
    }

    get name() {
        let name = '';

        if (this.user.firstName) {
            name += this.user.firstName + ' ';
        }

        if (this.user.lastName) {
            name += this.user.lastName;
        }

        if (name === '') {
            name = this.user.userName;
        }

        return name;
    }

    get profilePictureChangeUrl() {
        const baseURL = this.configuration.value?.api?.dericonId?.baseUrl || 'https://id.dericon.it';

        return `${baseURL}/profile`;
    }
}

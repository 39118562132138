<div class="alert-panel__header">
    <h4>{{'shared.components.alertPanel.title' | transloco}}</h4>

    <button  *ngIf="alerts && alerts.length > 0" class="alert-panel__clear-button" (click)="handleClickClearAll($event)">
        {{'shared.components.alertPanel.clearAll' | transloco}}
    </button>
</div>


<div class="alert-panel__container">
    <app-overlay-scrollbars
        [options]="{
            className: 'os-theme-dark mercury-overlay-scrollbars',
            scrollbars: { clickScrolling: true }
        }"
    >
        <div class="alert-panel__container__content">
            <ng-container *ngIf="alerts && alerts.length else: noAlerts">
                <app-alert-panel-item
                        *ngFor="let alert of alerts; let last = last"
                        [alert]="alert"
                        [last]="last"
                        (alertClosed)="handleAlertItemClosed(alert, $event)"
                        (alertActionClicked)="handlerAlertActionClicked($event, alert)"
                >
                </app-alert-panel-item>
            </ng-container>
            <ng-template #noAlerts>
                <h3>{{'shared.components.alertPanel.noAlerts' | transloco}}</h3>
            </ng-template>
        </div>
    </app-overlay-scrollbars>
</div>

import { ComponentFactoryResolver, Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { TabTemplateRefComponent } from './tab-template-ref.component';
import { TabManagerQuery } from '../../state/tab-manager/tab-manager.query';
import { TabManagerService } from '../../state/tab-manager/tab-manager.service';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

const nbTabLinkComponents = 0;

@Directive({
    selector: '[appTabLink]',
    host: {
        '[class.btn]': 'true',
        role: 'tab',
        type: 'button'
    }
})
export class TabLinkDirective implements OnDestroy {
    private _inOverflow = false;
    private tabSubscription: Subscription;

    tabLinkId: string;
    _tabsId: string;

    calculatedWith = 0;

    @Input()
    overflowTitle = '';

    @Input('clrTabLinkInOverflow')
    set inOverflow(inOverflow) {
        this._inOverflow = inOverflow;
    }

    get inOverflow(): boolean {
        return this._inOverflow;
    }



    @HostBinding('class.btn-link')
    @HostBinding('class.nav-link')
    get addLinkClasses() {
        return !this.inOverflow;
    }

    @HostBinding('class.active')
    @HostBinding('attr.aria-selected')
    isActive = false;

    @Input()
    @HostBinding('class.close-action')
    hasCloseAction = false;

    templateRefContainer: TabTemplateRefComponent;

    constructor(
        public el: ElementRef,
        private cfr: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private tabManagerQuery: TabManagerQuery,
        private tabManagerService: TabManagerService
    ) {

        // Tab links can be rendered in one of two places: in the main area or inside the overflow dropdown menu.
        // Here, we create a container so that its template can be used to create embeddedView on the fly.
        // See TabsService's renderView() method and how it's used in Tabs class for an example.
        const factory = this.cfr.resolveComponentFactory(TabTemplateRefComponent);
        this.templateRefContainer = this.viewContainerRef.createComponent(factory, 0, undefined, [
            [this.el.nativeElement]
        ]).instance;
    }

    set tabsId(id: string) {
        this._tabsId = id;

        if (this.tabSubscription) {
            this.tabSubscription.unsubscribe();
        }

        this.tabSubscription = this.tabManagerQuery.getActiveTabsIdFromGroup$(this._tabsId).pipe(delay(0)).subscribe(activeId => {
            this.isActive = activeId === this.tabLinkId;
        });
    }

    ngOnDestroy(): void {
        if (this.tabSubscription) {
            this.tabSubscription.unsubscribe();
        }
    }

    @HostListener('click')
    activate() {
        this.tabManagerService.setTabActiveInGroup(this._tabsId, this.tabLinkId);
    }


}

import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AlertsQuery } from '../../state/alerts/alerts.query';
import { Subscription } from 'rxjs';
import { AlertsService } from '../../state/alerts/alerts.service';

@Component({
    selector: 'app-topbar-settings-panel',
    templateUrl: './topbar-settings-panel.component.html'
})
export class TopbarSettingsPanelComponent implements OnInit, OnDestroy {
    @ViewChild('template', { static: true }) template;

    settingsForm: UntypedFormGroup;
    private dataSubscription: Subscription;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private fb: UntypedFormBuilder,
        private alertsQuery: AlertsQuery,
        private alertsService: AlertsService
    ) {
        this.settingsForm = this.fb.group({
            muteNotifications: ['']
        });


        this.dataSubscription = this.alertsQuery.muteNotificationSounds$.subscribe(value => {
            this.settingsForm.patchValue({ muteNotifications: value }, { emitEvent: false });
        });

        this.dataSubscription.add(this.settingsForm.controls.muteNotifications.valueChanges.subscribe(value => {
            this.alertsService.setMuteNotificationSounds(value);
        }));
    }

    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

}

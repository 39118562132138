import { Injectable } from '@angular/core';
import { MercuryBrokerApi } from './mercury-broker.api';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';
import { Observable } from 'rxjs';
import { ContentProduct } from '../../routes/feeds/state/contents/content.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ProductsApi extends MercuryBrokerApi {
    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super('products', true, http, configurationService);
    }

    findProductInfo(id: string): Observable<ContentProduct> {
        return this.get(`${id}`).pipe(
            map(response => response.data)
        );
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { Page } from '../../components/topbar/topbar.interface';
import { ConfigurationService } from '../../services/configuration.service';


@Component({
    selector: 'app-default-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.scss'],
    host: { '[class.default-layout]': 'true' }
})
export class DefaultLayoutComponent implements OnInit {

    @Input() page: Page;
    @Input() unpadded = true;

    constructor(public config: ConfigurationService) {
    }

    ngOnInit() {
    }
}

import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { TabComponent } from './tab.component';
import { TabLinkDirective } from './tab-link.directive';
import { TabContentComponent } from './tab-content.component';
import { Subscription } from 'rxjs';
import { TabManagerQuery } from '../../state/tab-manager/tab-manager.query';
import { TabManagerService } from '../../state/tab-manager/tab-manager.service';
import { ClrPopoverToggleService } from '@clr/angular';
import { delay } from 'rxjs/operators';
import { AppOverlayScrollbarsComponent } from '../overlay-scrollbars/app-overlay-scrollbars.component';

@Component({
    selector: 'app-tabs',
    template: `
        <div class="nav" [style.maxWidth]="maxWidth + 'px'">
            <app-overlay-scrollbars
                    #overlayScrollbarsComponent
                    [options]="{
                        className: 'os-theme-dark',
                        sizeAutoCapable: true,
                        scrollbars: {
                            clickScrolling: true,
                            autoHideDelay: 100,
                            autoHide: 'leave',
                            snapHandle: true
                        },
                        overflowBehavior: {
                            x: 'scroll',
                            y: 'hidden'
                        }
                    }"
                    [extensions]="['horizontalScrolling']"
            >
                <ul role="tablist">
                    <!--tab links-->
                    <ng-container *ngFor="let link of tabLinkDirectives; trackBy: trackByFn">
                        <li role="presentation" class="nav-item" [class.close-action]="link.hasCloseAction">
                            <ng-container [ngTemplateOutlet]="link.templateRefContainer.template"></ng-container>
                        </li>
                    </ng-container>

                    <ng-container *ngIf="overflowTabs.length">
                        <div class="tabs-overflow bottom-right" [class.open]="popoverToggleService.open" role="presentation">
                            <li role="application" class="nav-item" (click)="toggleOverflow($event)">
                                <button class="btn btn-link nav-link dropdown-toggle" type="button" aria-hidden="true">
                                    <clr-icon shape="ellipsis-horizontal"
                                              [class.is-info]="popoverToggleService.open"></clr-icon>
                                </button>
                            </li>
                            <!--tab links in overflow menu-->
                            <clr-tab-overflow-content>
                                <ng-container *ngFor="let linkOverflow of overflowTabs">
                                    <button class="btn"
                                            (click)="clickOverflowLink($event, linkOverflow)">{{linkOverflow.overflowTitle}}</button>
                                </ng-container>
                            </clr-tab-overflow-content>
                        </div>
                    </ng-container>
                </ul>
            </app-overlay-scrollbars>
        </div>
        <ng-container *ngFor="let content of tabContentComponents">
            <ng-container [ngTemplateOutlet]="content.template"></ng-container>
        </ng-container>
    `,
    styles: []
})
export class TabsComponent implements AfterContentInit, OnDestroy, OnChanges, OnInit {
    private subscriptions: Subscription[] = [];

    @ContentChildren(TabComponent)
    private tabs: QueryList<TabComponent>;

    @Input()
    tabsId: string;

    @Input()
    maxWidth: number;

    private _tabLinkDirectives: TabLinkDirective[] = [];
    tabLinkElements: HTMLElement[] = [];

    private _tabContentComponents: TabContentComponent[] = [];
    overflowTabs: TabLinkDirective[] = [];

    activeTabId: string;

    @ViewChild('overlayScrollbarsComponent', { static: true })
    overlayScrollbarsComponent: AppOverlayScrollbarsComponent;

    isInOverflow(tab: TabLinkDirective) {
        return this.overflowTabs.indexOf(tab) !== -1;
    }

    isActiveTabInOverflow() {
        return this.overflowTabs.findIndex(tab => tab.tabLinkId === this.activeTabId) !== -1;
    }

    get tabLinkDirectives(): TabLinkDirective[] {
        return this._tabLinkDirectives;
    }

    get tabContentComponents(): TabContentComponent[] {
        return this._tabContentComponents;
    }

    constructor(
        public popoverToggleService: ClrPopoverToggleService,
        private tabManagerQuery: TabManagerQuery,
        private tabManagerService: TabManagerService,
        private cd: ChangeDetectorRef
    ) {

        // this.activeTabIdFromGroup$ = this.tabManagerQuery.getActiveTabsIdFromGroup$(this.tabsId);
    }

    trackByFn(index, item: TabLinkDirective) {
        return item.tabLinkId; // or item.id
    }

    ngOnInit() {
        this.tabManagerService.setUpTabManagerGroup(this.tabsId);
    }

    ngAfterContentInit() {
        this.subscriptions.push(this.listenForTabLinkChanges());

        this.subscriptions.push(
            this.tabManagerQuery.getActiveTabsIdFromGroup$(this.tabsId).pipe(delay(0)).subscribe(activeId => {
                this.activeTabId = activeId;

                // scroll tab list to the active tab
                const osInstance = this.overlayScrollbarsComponent.osInstance();

                if (osInstance) {
                    const tab = this._tabLinkDirectives.find(l => l.tabLinkId === activeId);

                    if (tab) {
                        osInstance.scroll({
                            el: tab.el.nativeElement,
                            scroll: { x: 'ifneeded', y: 'never' },
                            block: ['begin', 'end'],
                            margin: 10
                        });
                    }
                }
            })
        );

        this.setUpTabs();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    setUpTabs() {
        this._tabLinkDirectives = this.tabs.map(tab => {
            tab.tabsId = this.tabsId;

            return tab.tabLink;
        });

        this.tabLinkElements = this._tabLinkDirectives.map(tab => {
            return tab.el.nativeElement;
        });

        this._tabContentComponents = this.tabs.map(tab => tab.tabContent);


        if (!this.tabManagerQuery.getActiveTabFromGroup(this.tabsId) && this.tabs.length) {
            this.tabManagerService.setTabActiveInGroup(this.tabsId, this.tabs.first.tabId);
        }
    }

    private listenForTabLinkChanges() {
        return this.tabs.changes.subscribe(() => {
            this.setUpTabs();
        });
    }

    findTabLinkNotInOverflow() {
        return this._tabLinkDirectives.find(t => this.overflowTabs.indexOf(t) === -1);
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    toggleOverflow(event: MouseEvent) {
        this.popoverToggleService.toggleWithEvent(event);
    }

    clickOverflowLink(event: MouseEvent, tab: TabLinkDirective) {
        event.preventDefault();
        this.tabManagerService.setTabActiveInGroup(this.tabsId, tab.tabLinkId);
    }
}

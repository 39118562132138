import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { TabLinkDirective } from './tab-link.directive';
import { TabContentComponent } from './tab-content.component';

@Component({
    selector: 'app-tab',
    template: `<ng-content></ng-content>`,
    styles: []
})
export class TabComponent implements AfterContentInit {
    @ContentChild(TabLinkDirective, { static: true })
    tabLink: TabLinkDirective;

    @ContentChild(TabContentComponent, { static: true })
    tabContent: TabContentComponent;

    @Input('tabsId')
    _tabsId: string;

    @Input('id')
    tabId: string;

    ngAfterContentInit() {
        this.tabLink.tabLinkId = this.tabId;
        this.tabContent.tabContentId = this.tabId;
    }

    set tabsId(id: string) {
        this._tabsId = id;

        if (this.tabLink) {
            this.tabLink.tabsId = id;
        }

        if (this.tabContent) {
            this.tabContent.tabsId = id;
        }
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { GroupsStore } from './groups.store';
import { delay, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, Subject, timer } from 'rxjs';
import { runOnce, truthy } from '../../../../shared/helpers/observable.helper';
import { GroupsQuery } from './groups.query';
import { GroupList, GroupMetaResponse, GroupWrapper, HiddenFeature, searchGroupDefinition } from './group.model';
import { TabManagerService } from '../../../../shared/state/tab-manager/tab-manager.service';
import { TabGroups } from '../../../../shared/state/tab-manager/tab-manager.model';
import { GroupsApi } from './groups.api';
import { AlertsService } from '../../../../shared/state/alerts/alerts.service';
import { differenceWith, toPairs } from 'lodash-es';
import { AlertsQuery } from '../../../../shared/state/alerts/alerts.query';
import { AlertActionType, createNewMustReadAlert } from '../../../../shared/state/alerts/alert.model';
import { ContentsQuery } from '../contents/contents.query';
import { ContentsStore } from '../contents/contents.store';

function flattenNavigationGroups(groups: GroupList, parentId = null) {
    const items = [];

    groups.forEach(item => {
        const id = item.id = item.id || ('' + item.label).toUpperCase();

        items.push({
            ...item,
            id,
            parentId,
            navigationItems: null
        });

        if (item.navigationItems) {
            items.push(...[].concat(flattenNavigationGroups(item.navigationItems, id)));
        }
    });

    return items;
}

@Injectable({ providedIn: 'root' })
export class GroupsService implements OnDestroy {
    updateNavigationSeenMeta$: Observable<GroupMetaResponse> = timer(0, 10000).pipe(
        switchMap(() => this.groupsApi.getNavigationSeenMeta()),
        tap((data: GroupMetaResponse) => {
            if (!this.groupsQuery.isFeatureHidden(HiddenFeature.MUST_READ)) {
                this.reconcileGroupNewMustReadAndPlaySound(data);
            }

            this.groupsStore.updateNavigationSeenMeta(data);
        })
    );
    private killTrigger$: Subject<void> = new Subject();

    constructor(private groupsStore: GroupsStore,
                private groupsQuery: GroupsQuery,
                private groupsApi: GroupsApi,
                private contentsQuery: ContentsQuery,
                private contentsStore: ContentsStore,
                private alertsQuery: AlertsQuery,
                private alertsService: AlertsService,
                private tabManagerService: TabManagerService,
                private http: HttpClient) {

        this.updateNavigationSeenMeta$.subscribe();
    }


    get(activeGroup?: string) {
        this.groupsStore.setLoading(true);

        return this.groupsApi.getAllGroups().pipe(
            filter(truthy),
            map(groups => {
                return [
                    groups,
                    flattenNavigationGroups(groups.navigationGroups)
                ] as [GroupWrapper, GroupList];
            }),
            tap(([groups, items]) => {
                applyTransaction(() => {
                    this.groupsStore.setGroups(groups);
                    this.groupsStore.setGroupEntities(items);
                    this.groupsStore.setLoading(false);

                    const inGroups = items.find(c => c.id === activeGroup);
                    const defaultGroup = items.filter(c => !!c.parentId)[0];

                    if ((activeGroup && inGroups) || (activeGroup === 'search')) {
                        this.selectGroup(activeGroup);
                    } else {
                        const selectedGroup = defaultGroup || items[0];
                        this.selectGroup(selectedGroup.id);
                    }
                });
            })
        );
    }

    getContentCreationGroups() {
        return this.groupsApi.getContentCreationGroups().pipe(
            filter(truthy),
            tap(groups => {
                this.groupsStore.setContentCreationGroups(groups);
            })
        );
    }

    load(activeGroup?: string) {
        runOnce(this.get(activeGroup));
    }

    loadContentCreationGroups() {
        runOnce(this.getContentCreationGroups());
    }

    selectGroup(group: string) {
        let selectedGroup = null;

        if (group === 'search') {
            selectedGroup = { ...searchGroupDefinition };
        } else {
            selectedGroup = this.groupsStore.getValue().entities.find(item => item.id === group);
        }

        const groupViewConfig = selectedGroup && selectedGroup.view;
        const availableViews = groupViewConfig && groupViewConfig.available;
        const defaultTabId = (groupViewConfig && groupViewConfig.default) || (
            availableViews && availableViews.length ? availableViews[0] : null
        );

        this.tabManagerService.setUpTabManagerGroup(TabGroups.GROUPINGS, availableViews || [], defaultTabId);
        this.contentsStore.setFilter(defaultTabId as string);
        this.tabManagerService.setTabActiveInGroup(TabGroups.GROUPINGS, defaultTabId);
        this.groupsStore.setActiveGroup(group);
    }

    ngOnDestroy() {
        this.killTrigger$.next();
    }

    reconcileGroupNewMustReadAndPlaySound(newData: GroupMetaResponse) {
        this.groupsQuery.groupsMeta$.pipe(take(1)).subscribe(
            oldData => {
                const oldDataPair = toPairs(oldData);
                const newDataPair = toPairs(newData);

                const anyDifference = differenceWith(newDataPair, oldDataPair, (a, b) => {
                    return a[1].unseenMustRead <= b[1].unseenMustRead && a[1].unseenRegular <= b[1].unseenRegular;
                }).filter(d => d[1].unseenMustRead > 0 || d[1].unseenRegular > 0);

                if (anyDifference.length) {
                    const anyDifferenceIds = anyDifference.map(d => d[0]);

                    if (anyDifferenceIds.includes(this.groupsQuery.getActiveId())) {
                        if (this.contentsQuery.getCount()) {
                            this.contentsStore.setHasNewContents(true);
                        }
                    }
                }

                const difference = differenceWith(newDataPair, oldDataPair, (a, b) => {
                    return a[1].unseenMustRead <= b[1].unseenMustRead;
                }).filter(d => d[1].unseenMustRead > 0);

                if (difference.length) {
                    const differenceIds = difference.map(d => d[0]);
                    const mustReadPlaySound = [];
                    const mutedGroups = this.alertsQuery.getValue().ui.mutedGroupings || {};

                    this.groupsQuery.groupListItems$.pipe(take(1)).subscribe(items => {
                        let countNewMustRead = 0;
                        const newMustReadGroups: { id: string, label: string }[] = [];

                        difference.forEach(d => {
                            const diffId = d[0];
                            const found = items.find(item => item.id === diffId);

                            countNewMustRead += d[1].unseenMustRead;

                            if (found) {
                                newMustReadGroups.push({
                                    id: diffId,
                                    label: found.label
                                });

                                if (!mutedGroups[found.id] && !mutedGroups[found.parentId]) {
                                    mustReadPlaySound.push(diffId);
                                }
                            }
                        });

                        this.alertsService.show(createNewMustReadAlert({
                            action: {
                                label: 'Open group',
                                extra: {
                                    id: differenceIds[0]
                                },
                                type: AlertActionType.OPEN_GROUP
                            },
                            extra: {
                                groups: newMustReadGroups,
                                newMustReadCount: countNewMustRead
                            }
                        }));

                        this.groupsStore.updateFlashGroups(differenceIds);

                        if (mustReadPlaySound.length && !this.alertsQuery.isNotificationSoundsMuted()) {
                            this.alertsService.playNewMustReadSound();
                        }

                        setTimeout(() => {
                            this.groupsStore.updateFlashGroups([]);
                        }, 2000);
                    });
                }
            }
        );
    }
}

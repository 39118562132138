<div class="dev-toolbar" *ngIf="visible">
    <a href="#" (click)="onClickClose($event)">&times;</a>
    <div>Environment: </div>
    <select class="form-control" (change)="onEnvironmentSelect($event)">
        <option
            *ngFor="let environment of environments"
            [selected]="environment === selectedEnvironment"
            [value]="environment">{{environment}}</option>
    </select>
    <div>
        <app-button level="warning" (clicked)="onClickResetToken($event)">Reset auth token</app-button>
    </div>
    <div>
        <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="productionData" [ngModel]="production" (ngModelChange)="onChangeProduction($event)" />
            <label>Use production data</label>
        </clr-toggle-wrapper>
    </div>
</div>

import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { Location } from '@angular/common';

interface NavigatorExtras extends NavigationExtras {
    silentLocationChange?: boolean;
    preserveFragment?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigatorService {

    constructor(private location: Location, private router: Router) {
    }

    navigate(commands: any[], extras: NavigatorExtras = { }) {
        this.router.navigate(commands, extras);

        /*if(extras.silentLocationChange){
            const tree: UrlTree = this.router.createUrlTree(commands, extras);

            this.location.replaceState(tree.toString());
        } else {

        }*/
    }

    getHrefFromCommands(commands: any, extras?: NavigatorExtras) {
        const tree: UrlTree = this.router.createUrlTree(commands, extras);

        return tree.toString();
    }
}

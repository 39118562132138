import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tab-template-ref',
    template: `
        <ng-template>
            <ng-content></ng-content>
        </ng-template>
    `,
    styles: []
})
export class TabTemplateRefComponent {
    @ViewChild(TemplateRef)
    template: TemplateRef<any>;
}

import { ID } from '@datorama/akita';

export enum TabGroups {
    GROUPINGS = 'GROUPINGS',
    CONTENTS = 'CONTENTS'
}

export enum ActionPosition {
    PREFIX,
    SUFFIX
}

export interface TabManager {
    id: string;
}

export interface TabAction {
    id?: string;
    type: string;
    icon: string;
    title: string;
    position: ActionPosition;
}

export interface Tab {
    id: string;
    title: string;

    icon: string;
    actions: TabAction[];

    contentType: string;
    contentData: any;
}

export interface TabGroup {
    id: string;
    activeTabId: string;
    tabs: string[];
}

/**
 * A factory function that creates TabManager
 */
export function createTabManager(params: Partial<TabManager>) {
    return {

    } as TabManager;
}

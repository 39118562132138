import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


export class BaseApi<T = ApiResponse> {
    constructor(
        protected baseUrl: string,
        protected http: HttpClient,
        protected authenticated: boolean = true
    ) {
    }

    private getHeaders(apiOptions: ApiOptions): { [header: string]: string | string[] } {
        const headers = apiOptions.headers || {};
        if (apiOptions.body) {
            headers['Content-Type'] = 'application/json';
        }

        if (apiOptions.basicAuth) {
            headers.Authorization = `Basic ${window.btoa(
                String.fromCharCode(
                    ...new TextEncoder()
                        .encode(apiOptions.basicAuth.username + ':' + apiOptions.basicAuth.password)
                )
            )}`;
        }

        if (this.authenticated || apiOptions.authenticated) {
            headers['X-Add-Authentication'] = 'yes';
        }

        return headers;
    }

    get(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };
        if (endpoint.length > 0) {
            return this.http.get<T>(`${this.baseUrl}/${endpoint}`, options);
        } else {
            return this.http.get<T>(`${this.baseUrl}`, options);
        }
    }

    post(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };
        return this.http.post<T>(`${this.baseUrl}/${endpoint}`, body, options);
    }

    put(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        return this.http.put<T>(`${this.baseUrl}/${endpoint}`, body, options);
    }

    patch(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        return this.http.patch<T>(`${this.baseUrl}/${endpoint}`, body, options);
    }

    delete(endpoint: string, apiOptions: ApiOptions = {}): Observable<T> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions)
        };

        return this.http.delete<T>(`${this.baseUrl}/${endpoint}`, options);
    }
}

export interface ApiResponse {
    data: any;
    meta: any;
}

export interface ApiOptions {
    token?: string;
    authenticated?: boolean;
    basicAuth?: {
        username: string;
        password: string;
    };
    body?: any;
    headers?: {
        [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    observe?: any;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}

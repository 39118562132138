import { Injectable, OnDestroy } from '@angular/core';
import { AuthStore } from './auth.store';
import { AuthToken, User } from './auth.model';
import { switchMap, tap } from 'rxjs/operators';
import { AuthApi } from '../../api/auth.api';
import { AuthQuery } from './auth.query';
import { Params, Router } from '@angular/router';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { GroupsStore } from '../../../routes/feeds/state/groups';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private authStore: AuthStore,
        private authQuery: AuthQuery,
        private groupsStore: GroupsStore,
        private authApi: AuthApi,
        private router: Router
    ) {
    }

    login(username: string, password: string) {
        return this.authApi.getTokenUsingCredentials(username, password).pipe(
            tap((token: AuthToken) => {
                this.authStore.updateAuthToken(token);
            }),
            switchMap(() => this.refreshUserInformation())
        );
    }

    resetSession() {
        this.authStore.reset();
        this.groupsStore.resetNavigationSeenMeta();
    }

    resetToken() {
        this.authStore.resetAuthToken();
    }

    logout(queryParams?: Params) {
        return new Observable(observable => {
            this.resetSession();
            this.router.navigate(['/auth/login'], { queryParams });

            observable.next();
            observable.complete();
        });
    }

    refreshUserInformation() {
        return this.authApi.getAuthenticatedUser().pipe(
            tap((user: User) => {
                this.authStore.updateUser(user);
            })
        );
    }

    refreshToken() {
        if (!this.authQuery.getAuthToken()) {
            return throwError(new Error('No auth token'));
        }

        return this.authApi.getTokenUsingRefreshToken(this.authQuery.getAuthToken().refreshToken).pipe(
            tap((token: AuthToken) => {
                this.authStore.updateAuthToken({ token: token.token, tokenExpires: token.tokenExpires });
            })
        );
    }

    refreshTokenWithRedirectToken(redirectToken: string) {
        return this.authApi.getTokenUsingRedirectToken(redirectToken).pipe(
            tap((token: AuthToken) => {
                this.authStore.updateAuthToken({ ...token });
            })
        );
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    refreshUserIfTokenValid() {
        if (this.authQuery.isRefreshTokenStillValid()) {
            return this.refreshUserInformation();
        }

        return of(null);
    }
}

import {
    AfterContentInit,
    Component,
    ContentChildren,
    ElementRef,
    OnInit,
    QueryList
} from '@angular/core';
import { RadioButtonGroupButtonComponent } from './radio-button-group-button.component';

@Component({
    selector: 'app-radio-button-group',
    template: `
        <ng-container *ngFor="let button of inlineButtons">
            <ng-template [ngTemplateOutlet]="button.templateRef"></ng-template>
        </ng-container>
    `,
    host: { '[class.btn-group]': 'true', '[class.btn-sm]': 'true'}
})
export class RadioButtonGroupComponent implements OnInit, AfterContentInit {
    @ContentChildren(RadioButtonGroupButtonComponent) buttons: QueryList<RadioButtonGroupButtonComponent>;

    inlineButtons: RadioButtonGroupButtonComponent[] = [];

    constructor(private elementRef: ElementRef) {
    }

    ngOnInit() {
    }

    ngAfterContentInit() {
        this.initializeButtons();
    }

    initializeButtons() {
        this.buttons.forEach(button => {
            this.inlineButtons.push(button);
        });
    }

}

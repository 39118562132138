import { Injectable } from '@angular/core';
import { ID, Query } from '@datorama/akita';
import { TabManagerStore, TabManagerState } from './tab-manager.store';
import { truthy } from '../../helpers/observable.helper';
import { filter, map, startWith } from 'rxjs/operators';
import { TabGroup } from './tab-manager.model';

@Injectable({ providedIn: 'root' })
export class TabManagerQuery extends Query<TabManagerState> {
    constructor(protected store: TabManagerStore) {
        super(store);
    }

    getTabGroupById$(id: string) {
        return this.select(store => store.groups[id]);
    }

    getActiveTabsIdFromGroup$(id: string) {
        return this.getTabGroupById$(id).pipe(filter(truthy), map(group => group.activeTabId), startWith<string>(null));
    }

    getActiveTabFromGroup(id: string) {
        if (!this.getValue().groups.hasOwnProperty(id)) {
            return null;
        }

        return this.getValue().groups[id].activeTabId;
    }
}

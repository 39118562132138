import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-theme-switcher',
    templateUrl: './theme-switcher.component.html'
})
export class ThemeSwitcherComponent implements OnInit {
    @ViewChild('template', { static: true }) template;

    themes = ['dericon'];
    currentTheme: string;


    constructor(
        private themeService: ThemeService,
        private configurationService: ConfigurationService,
        private viewContainerRef: ViewContainerRef
    ) {
        this.currentTheme = this.configurationService.value.theme;
    }

    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    onThemeClick(theme: string) {
        this.currentTheme = theme;
        this.themeService.applyTheme(theme);
    }

}

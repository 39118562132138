import { Injectable } from '@angular/core';
import { MercuryBrokerApi } from '../../../../shared/api/mercury-broker.api';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../../../../shared/services/configuration.service';
import { getDynamicQueryParams } from '../contents/contents.transform';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GroupMetaResponse, GroupViewType, GroupWrapper } from './group.model';

@Injectable({ providedIn: 'root' })
export class GroupsApi extends MercuryBrokerApi {
    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super('navigations', true, http, configurationService);
    }

    getAllGroups(): Observable<GroupWrapper> {
        return this.get('displays').pipe(map(response => response.data));
    }

    getNavigationSeenMeta(): Observable<GroupMetaResponse> {
        return this.get('unseen').pipe(map(response => response.data));
    }

    getContentCreationGroups(): Observable<GroupWrapper> {
        return this.get('contents').pipe(map(response => response.data));
    }
}

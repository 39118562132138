import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Alert, AlertType } from '../../state/alerts/alert.model';
import { AlertsQuery } from '../../state/alerts/alerts.query';
import { AlertsService } from '../../state/alerts/alerts.service';
import { Observable } from 'rxjs';
import { Order } from '@datorama/akita';

@Component({
    selector: 'app-alert-button',
    templateUrl: './alert-button.component.html',
    styleUrls: ['./alert-button.component.scss']
})
export class AlertButtonComponent implements OnInit {
    AlertType = AlertType;

    @ViewChild('template', { static: true }) template;

    hasUnreadAlerts$: Observable<boolean>;
    alerts$: Observable<Alert[]>;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private alertsQuery: AlertsQuery,
        private alertsService: AlertsService
    ) {
        this.hasUnreadAlerts$ = this.alertsQuery.hasUnreadAlerts$;
        this.alerts$ = this.alertsQuery.selectAll({ sortBy: 'date', sortByOrder: Order.DESC });
    }

    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
        this.alertsService.load();
    }

    onNotificationClick(notification: Alert) {

    }

}

import { AlertsStore } from './alerts.store';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Alert, AlertActionType, AlertType, createNewContentAlert } from './alert.model';
import { AlertsQuery } from './alerts.query';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { Howl } from 'howler';
import { NavigatorService } from '../../services/navigator.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class AlertsService {
    private mustReadSound = new Howl({
        // From: https://notificationsounds.com/message-tones/filling-your-inbox-251
        src: ['/assets/sounds/new_must_read.webm', '/assets/sounds/new_must_read.mp3'],
        preload: true,
        onplayerror: () => {
            this.mustReadSound.once('unlock', () => {
                this.mustReadSound.play();
            });
        }
    });

    constructor(
        private alertsStore: AlertsStore,
        private alertsQuery: AlertsQuery,
        private toastrService: ToastrService,
        private translocoService: TranslocoService,
        private navigatorService: NavigatorService
    ) {
        /*setTimeout(() => {
            this.show(createNewContentAlert({
                title: 'New Message From Christoph Stach',
                text: `Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print,
                graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is
                thought to have scrambled parts of Cicero\'s De Finibus Bonorum et Malorum for
                use in a type specimen book.`,
                type: AlertType.SUCCESS,
                extra: {
                    author: 'Alexander Brakowski',
                    authorId: '1',
                    contentId: '1'
                }
            }));
        }, 3000);*/
    }

    hasNativeNotificationsPermission() {
        if (!('Notification' in window)) {
            return false;
        } else if (Notification.permission === 'granted') {
            return true;
        } else if (Notification.permission !== 'denied') {
            return false;
        } else {
            return false;
        }
    }

    requestNativeNotificationPermissions() {
        if (!('Notification' in window)) {
            this.alertsStore.updateNativeNotificationsPermission(false);
        } else if (Notification.permission === 'granted') {
            this.alertsStore.updateNativeNotificationsPermission(true);
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    this.alertsStore.updateNativeNotificationsPermission(true);
                } else {
                    this.alertsStore.updateNativeNotificationsPermission(false);
                }
            });
        } else {
            this.alertsStore.updateNativeNotificationsPermission(false);
        }
    }

    get(): Observable<Alert[]> {
        return of([]);
    }

    load() {
        this.alertsStore.setLoading(true);
        this.get().subscribe(notifications => {
            this.alertsStore.set(notifications);
            this.alertsStore.setLoading(false);
        });
    }

    removeAlert(id: string) {
        this.alertsStore.removeAlert(id);
    }

    removeAllAlerts() {
        this.alertsStore.removeAllAlerts();
    }

    showToast(alert: Alert) {
        if (this.hasNativeNotificationsPermission()) {
            const notification = new Notification(alert.title, { body: alert.text });

            notification.addEventListener('click', () => {
                this.onAlertActivate(alert);
            });
        }

        switch (alert.type) {
            case AlertType.SUCCESS:
                this.toastrService.success(alert.text, alert.title)
                    .onTap
                    .pipe(take(1))
                    .subscribe(() => this.onAlertActivate(alert));
                break;

            case AlertType.WARNING:
                this.toastrService.warning(alert.text, alert.title)
                    .onTap
                    .pipe(take(1))
                    .subscribe(() => this.onAlertActivate(alert));
                break;

            case AlertType.ERROR:
                this.toastrService.error(alert.text, alert.title)
                    .onTap
                    .pipe(take(1))
                    .subscribe(() => this.onAlertActivate(alert));
                break;

            case AlertType.INFO:
            default:
                this.toastrService.info(alert.text, alert.title)
                    .onTap
                    .pipe(take(1))
                    .subscribe(() => this.onAlertActivate(alert));

        }
    }

    show(alert: Alert) {

        let translateStrings = null;
        let translateParams = {};

        if (alert.translateTitle) {
            translateStrings = {};
            translateStrings[alert.title] = 'title';
            translateParams = { ...translateParams, ...(alert.translateTitleParams || {}) };
        }

        if (alert.translateText) {
            translateStrings = translateStrings || {};
            translateStrings[alert.text] = 'text';
            translateParams = { ...translateParams, ...(alert.translateTextParams || {}) };
        }

        if (translateStrings) {
            const translations = this.translocoService.translate(Object.keys(translateStrings), translateParams);
            const copyAlert = { ...alert, title: translations[0], text: translations[1] };

            this.alertsStore.add(copyAlert);
            this.showToast(copyAlert);
        } else {
            this.alertsStore.add(alert);
            this.showToast(alert);
        }
    }

    onAlertActivate(alert: Alert) {
        this.alertsStore.changeAlertUnreadState(alert, false);

        if (alert.action) {
            const action = alert.action;

            switch (action.type) {
                case AlertActionType.OPEN_GROUP:
                    if (action.extra && action.extra.id) {
                        const url = this.getLinkUrlForNavigation(action.extra.id);
                        this.navigatorService.navigate(url.commands, {
                            replaceUrl: true,
                            queryParams: { ...url.queryParams }
                        });
                    }

                    break;
                case AlertActionType.NONE:
                default: {
                    break;
                }
            }
        }
    }

    getLinkUrlForNavigation(group: string) {
        const path = ['/app', 'feeds'];

        return { commands: path, queryParams: { grouping: group } };
    }

    playNewMustReadSound() {
        this.mustReadSound.play();
    }

    setMutedGroupingState(id: string, value: boolean) {
        this.alertsStore.setMutedGroupingState(id, value);
    }

    setMuteNotificationSounds(value: boolean) {
        this.alertsStore.updateMuteNotificationSounds(value);
    }
}

import { Component, ContentChild, ContentChildren, ElementRef, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AutoCompleteContentDirective } from '../../directives/auto-complete-content.directive';
import { AutoCompleteOptionComponent } from '../auto-complete-option/auto-complete-option.component';
import { merge } from 'rxjs';
import { AppOverlayScrollbarsComponent } from '../overlay-scrollbars/app-overlay-scrollbars.component';

enum SelectDirection { PREVIOUS, NEXT }

@Component({
    selector: 'app-auto-complete',
    templateUrl: './auto-complete.component.html',
    styleUrls: ['./auto-complete.component.scss'],
    exportAs: 'appAutoComplete'
})
export class AutoCompleteComponent {
    @ViewChild('root') rootTemplate: TemplateRef<any>;
    @ViewChild('overlayScrollbars') overlayScrollbarsComponent: AppOverlayScrollbarsComponent;
    @ContentChild(AutoCompleteContentDirective) content: AutoCompleteContentDirective;
    @ContentChildren(AutoCompleteOptionComponent) options: QueryList<AutoCompleteOptionComponent>;
    @ContentChildren(AutoCompleteOptionComponent, { read: ElementRef }) optionsRef: QueryList<ElementRef>;

    optionsClick() {
        return this.options.changes.pipe(
            switchMap((options: AutoCompleteOptionComponent[]) => {
                const clicks$ = options.map(option => option.click$);
                return merge(...clicks$);
            })
        );
    }

    optionsMouseLeave() {
        return this.options.changes.pipe(
            switchMap((options: AutoCompleteOptionComponent[]) => {
                const mouseLeaves$ = options.map(option => option.mouseLeave$);
                return merge(...mouseLeaves$);
            })
        );
    }

    optionsMouseEnter() {
        return this.options.changes.pipe(
            switchMap((options: AutoCompleteOptionComponent[]) => {
                const mouseEnters$ = options.map(option => option.mouseEnter$);
                return merge(...mouseEnters$);
            })
        );
    }

    unselectActiveOption() {
        this.options.forEach((option, index) => {
            if (option.active) {
                option.active = false;
                return false;
            }
        });
    }

    hasActiveOption() {
        return this.getActiveIndex(false) !== -1;
    }

    getActiveIndex(resetActive = true) {
        let activeElementIndex = -1;

        this.options.forEach((option, index) => {
            if (option.active) {
                activeElementIndex = index;
            }

            if (resetActive) {
                option.active = false;
            }
        });

        return activeElementIndex;
    }

    getActiveOption() {
        const activeElementIndex = this.getActiveIndex();

        return this.options.find((opt, index) => index === activeElementIndex);
    }

    private goToIndex(nextIndex: number = 0, direction: SelectDirection = SelectDirection.NEXT) {
        console.log({ nextIndex, direction });

        if (this.options.length) {
            if (nextIndex === -1) {
                this.unselectActiveOption();
            } else {
                this.options.find((opt, index) => index === nextIndex).active = true;

                const ref = this.optionsRef.find((item, index) => index === nextIndex);

                if (ref) {
                    this.overlayScrollbarsComponent.osInstance().scroll({
                        el: ref.nativeElement,
                        scroll: { y: 'ifneeded', x: 'never' }
                    }, 100);
                }
            }
        }
    }

    public selectNext() {
        const activeElementIndex = this.getActiveIndex();
        let nextIndex = 0;

        if (activeElementIndex > -1) {
            nextIndex = Math.min(activeElementIndex + 1, this.options.length - 1);
        }

        this.goToIndex(nextIndex, SelectDirection.NEXT);

    }

    public selectPrevious() {
        const activeElementIndex = this.getActiveIndex();
        const prevIndex = activeElementIndex > -1 ? activeElementIndex - 1 : -1;
        this.goToIndex(prevIndex, SelectDirection.PREVIOUS);
    }
}




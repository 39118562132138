import { Component, Input, OnInit } from '@angular/core';
import { Alert, AlertAction, AlertActionType, AlertType } from '../../state/alerts/alert.model';
import { AlertsService } from '../../state/alerts/alerts.service';
import { Group, GroupsService } from '../../../routes/feeds/state/groups';
import { NavigatorService } from '../../services/navigator.service';
import { isString } from '@datorama/akita';

@Component({
    selector: 'app-alert-panel',
    templateUrl: './alert-panel.component.html',
    styleUrls: ['./alert-panel.component.scss']
})
export class AlertPanelComponent implements OnInit {
    AlertType = AlertType;

    @Input()
    alerts: Alert[] = [];

    constructor(
        private alertsService: AlertsService
    ) {
    }

    ngOnInit() {
    }


    handleAlertItemClosed(alert: Alert, state: boolean) {
        this.alertsService.removeAlert(alert.id);
    }

    handleClickClearAll($event: MouseEvent) {
        this.alertsService.removeAllAlerts();
    }

    handlerAlertActionClicked(action: AlertAction, alert: Alert<any>) {
        this.alertsService.onAlertActivate(alert);
    }
}

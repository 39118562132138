export interface GroupMeta {
    unseenMustRead?: number;
    unseenRegular?: number;
}

export interface GroupMetaResponse {
    [key: string]: GroupMeta;
}

export enum GroupViewType {
    ALL = 'ALL',
    MUST_READ = 'MUST_READ',
    UNREAD = 'UNREAD'
}

export enum HiddenFeature {
    MUST_READ = 'MUST_READ',
    MAIL_NOTIFICATION = 'MAIL_NOTIFICATION',
    NEWSLETTER_REVIEW = 'NEWSLETTER_REVIEW'
}

export interface GroupViewConfig {
    default: GroupViewType;
    available: GroupViewType[];
}

export interface Group {
    id: string;
    label: string;

    defaultCategory?: string;
    categories?: string[];

    view?: GroupViewConfig;
    orders?: string[];
    type: string;

    link?: string;
    onlyLabel?: boolean;
    icon?: string;
    collapsible?: boolean;
    default?: boolean;
    meta?: GroupMeta;
    parentId?: string | null;
    translate?: boolean;
}

export interface GroupItem extends Group {
    navigationItems?: Group[];
    siblingItems?: Group[];
}

export type GroupList = GroupItem[];

export interface GroupWrapper {
    id: string;
    environment?: string;
    navigationGroups: GroupList;
    featureConfiguration?: {
        hiddenFeatures?: HiddenFeature[];
    };
    notifyConfiguration?: {
        label?: string,
        enabledByDefault?: boolean
    };
}

export const searchGroupDefinition = {
    id: 'search',
    label: 'Search',
    type: 'SEARCH',
    view: {
        default: GroupViewType.ALL,
        available: [GroupViewType.ALL]
    }
};

/**
 * A factory function that creates Categories
 */
export function createGroup(params: Partial<Group>) {
    return {} as Group;
}

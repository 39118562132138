import { ID } from '@datorama/akita';
import { Group } from '../groups';

export interface ContentSessionResponse {
    sessionId: string;
}

export interface ContentAuthor {
    userId: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    organizationId?: string;
    avatar?: string;
    userProfilePicture?: string;
}

export interface ContentAttachment {
    id: string;
    fileName: string;
    size: number;
    mimeType: string;
    createdAt: string;
    tags: string[];
}

export interface ContentAttachmentExternalLink {
    id: string;
    link: string;
}

export interface ContentProduct {
    dq: string;
    ids: {
        isin: string;
        wkn: string;
    };
    link: string;
    name: string;
    instrumentType: string;
}

export interface CommentModel {
    id: string;
    author: ContentAuthor;
    authorId?: string;

    revisionId?: string;
    revisionAuthor?: ContentAuthor;
    revisionAuthorId?: string;

    createdAt: string | Date;
    updatedAt: string | Date;

    active?: boolean;

    title?: string;
    body: any;

    environmentId?: string;
    parentContentId?: string;
    parentCommentId?: string;
}

export interface ContentCommentReply extends CommentModel {
}

export interface ContentComment extends CommentModel {
    replies: ContentCommentReply[];
}

export interface Content {
    id: string;
    environmentId?: string;

    author: ContentAuthor;
    authorId?: string;
    revisionAuthor?: ContentAuthor;
    revisionAuthorId?: string;

    title: string;
    body: any;
    bodySummary: string;

    commentsCount: number;
    comments: ContentComment[];

    createdAt: string | Date;
    updatedAt: string | Date;

    lastReply: string | Date;
    active?: boolean;
    mustRead: boolean;
    notify?: boolean;
    seen: boolean;
    seenUsers?: ContentAuthor[];
    seenInformation?: {
        seenCount: number;
        users?: ContentAuthor[];
    };
    bookmarked: boolean;

    products: ContentProduct[] | string[];
    attachments: ContentAttachment[] | string[];
    tags?: string[];
    categoryId: string;
    image?: string;

    isError?: boolean;

    meta?: {
        editable: boolean;
        deletable: boolean;
    }
}

/**
 * A factory function that creates Contents
 */
export function createContent(params: Partial<Content>, authorId?: string) {
    return {
        title: '',
        body: {},
        authorId,
        revisionAuthorId: authorId,
        mustRead: false,
        bookmarked: false,
        ...params
    } as Content;
}


export function transformContentDates(content: Content) {
    return {
        ...content,
        createdAt: new Date(content.createdAt),
        updatedAt: new Date(content.updatedAt),
        lastReply: content.lastReply && new Date(content.lastReply)
    };
}

export function createComment(body: any, authorId?: string): Partial<ContentComment> {
    return {
        body,
        replies: [],
        authorId,
        revisionAuthorId: authorId
    };
}

export function createReply(body: any, authorId?: string): Partial<ContentCommentReply> {
    return {
        body,
        authorId,
        revisionAuthorId: authorId
    };
}

export function createErrorContent(id: string, title: string = ''): Content {
    return {
        id,
        title,
        body: null,
        bodySummary: null,
        commentsCount: null,
        comments: [],
        createdAt: null,
        updatedAt: null,
        lastReply: null,
        mustRead: false,
        seen: false,
        bookmarked: false,
        attachments: [],
        products: [],
        author: null,
        authorId: null,
        categoryId: null,
        isError: true
    };
}

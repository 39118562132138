import { BaseApi } from './base.api';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';
import { forkJoin, Observable, throwError } from 'rxjs';
import { AuthToken, User } from '../state/auth/auth.model';
import { catchError, map } from 'rxjs/operators';
import { ID } from '@datorama/akita';

@Injectable({
    providedIn: 'root'
})
export class AuthApi extends BaseApi<any> {
    constructor(
        http: HttpClient,
        configurationService: ConfigurationService
    ) {
        super(configurationService.value.api.duse.baseUrl, http, false);
    }

    getTokenUsingRedirectToken(redirectToken: string): Observable<AuthToken> {
        return this.get('auth/token/redirect', {
            params: {
                redirectToken
            }
        });
    }

    getTokenUsingRefreshToken(refreshToken: string): Observable<AuthToken> {
        return this.get('auth/token/refresh', {
            params: {
                refreshtoken: refreshToken
            }
        });
    }

    getTokenUsingCredentials(username: string, password: string, refreshToken = true): Observable<AuthToken> {
        return this.get('auth/token', {
            params: {
                includeRefreshToken: JSON.stringify(refreshToken)
            },
            basicAuth: {
                username,
                password
            }
        });
    }

    getAuthenticatedUser(): Observable<User> {
        return forkJoin([
            this.get('auth/token/me?appId=mercury', {
                authenticated: true
            }),
            this.get('profile/me', {
                authenticated: true
            }),
        ]).pipe(
            map(([authMe, profileMe]) => {
                return {
                    id: authMe.id,
                    firstName: authMe.firstname,
                    lastName: authMe.lastname,
                    userName: authMe.username,
                    userProfilePicture: profileMe.profilePictureID,
                    _raw: authMe,
                    _rawProfile: profileMe,
                };
            }),
        );
    }

    public getRedirectTokenUsingRefreshToken(refreshToken: string): Observable<string> {
        return this.post('auth/token/redirect', refreshToken, {responseType: 'text'});
    }
}

<ng-template #template>
    <clr-dropdown>
        <button class="btn btn-link" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="cog" size="24"></clr-icon>
            <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <div class="top-bar__settings-panel__container">
                <form [formGroup]="settingsForm">
                    <clr-toggle-container clrInline>
                        <label>{{'shared.components.topbarSettingsPanel.settingsFormTitle' | transloco}}</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="options" formControlName="muteNotifications" />
                            <label>{{'shared.components.topbarSettingsPanel.settingsMuteNotifications' | transloco}}</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                </form>
            </div>
        </clr-dropdown-menu>
    </clr-dropdown>
</ng-template>

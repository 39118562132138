import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsState } from '../../state/forms/forms.store';
import { NavigatorService } from '../../services/navigator.service';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { GroupsQuery } from '../../../routes/feeds/state/groups';
import { ContentsService } from '../../../routes/feeds/state/contents/contents.service';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';
import { Observable, Subscription } from 'rxjs';
import { GlobalSearchResultsQuery } from '../../state/global-search/global-search-results.query';
import { GlobalSearchResultsService } from '../../state/global-search/global-search-results.service';
import { GlobalSearchResult, GlobalSearchResultType } from '../../state/global-search/global-search-result.model';

import { debounceTime, distinctUntilChanged, filter, pluck, tap } from 'rxjs/operators';
import { LatestGlobalSearchesService } from '../../state/global-search/latest-global-searches.service';
import { createLatestGlobalSearch } from '../../state/global-search/latest-global-search.model';
import { truthy } from '../../helpers/observable.helper';
import { AutoCompleteDirective } from '../../directives/auto-complete.directive';
import { NgFormsManager } from '@ngneat/forms-manager';

@Component({
    selector: 'app-global-search-bar',
    templateUrl: './global-search-bar.component.html'
})
export class GlobalSearchBarComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class.global-search-bar') classGlobalSearchBar = true;

    globalSearchResults$: Observable<GlobalSearchResult<any>[]>;
    activeGlobalSearchResultId$: Observable<string>;
    globalSearchValues$: Observable<{ query: string; }>;
    debouncedGlobalSearchValues$: Observable<{ query: string; }>;
    globalSearchValid$: Observable<boolean>;
    globalSearchResultsLoading$: Observable<boolean>;

    GlobalSearchResultType = GlobalSearchResultType;


    @ViewChild('autoComplete')
    autoComplete: AutoCompleteComponent;

    @ViewChild(AutoCompleteDirective, { static: true })
    autoCompleteDirective: AutoCompleteDirective;

    globalSearchForm: UntypedFormGroup;
    globalSearchFormControl: UntypedFormControl;
    globalSearchValid: boolean;

    private subscription: Subscription;

    constructor(
        private fb: UntypedFormBuilder,
        private formsManager: NgFormsManager<FormsState>,
        private navigatorService: NavigatorService,
        private routerQuery: RouterQuery,
        private categoriesQuery: GroupsQuery,
        private contentsService: ContentsService,
        private globalSearchResultsQuery: GlobalSearchResultsQuery,
        private globalSearchResultsService: GlobalSearchResultsService,
        private latestGlobalSearchesServices: LatestGlobalSearchesService
    ) {

    }

    ngOnInit() {
        this.globalSearchFormControl = new UntypedFormControl('', Validators.required);
        this.globalSearchForm = this.fb.group({
            query: this.globalSearchFormControl
        });

        this.formsManager.upsert('globalSearch', this.globalSearchForm);
        const queryParams: { query?: string } = this.routerQuery.getQueryParams();

        if (queryParams.query) {
            this.globalSearchForm.setValue({ query: queryParams.query });
        }

        this.globalSearchValid$ = this.formsManager.controlChanges('globalSearch').pipe(pluck('valid'));
        this.globalSearchResults$ = this.globalSearchResultsQuery.selectAllWithLatestSearches$;
        this.globalSearchResultsLoading$ = this.globalSearchResultsQuery.selectLoading();
        this.activeGlobalSearchResultId$ = this.globalSearchResultsQuery.selectActiveId();

        this.globalSearchValues$ = this.formsManager.controlChanges('globalSearch').pipe(
            filter(truthy),
            pluck('value'),
            distinctUntilChanged()
        );

        this.debouncedGlobalSearchValues$ = this.globalSearchValues$.pipe(
            debounceTime(200),
            tap(value => this.globalSearchResultsService.get(value.query).subscribe())
        );
    }

    ngAfterViewInit() {
        this.subscription = this.globalSearchValues$.subscribe();
        this.subscription.add(this.debouncedGlobalSearchValues$.subscribe());
        this.subscription.add(this.globalSearchValid$.subscribe(valid => {
            this.globalSearchValid = valid;
        }));
    }


    ngOnDestroy() {
        this.formsManager.unsubscribe();
        this.subscription.unsubscribe();
    }


    handleInputSubmit(event) {
        this.onOptionSelect(this.globalSearchForm.value.query);
    }

    onRemoveLatestGlobalSearchClick(event: Event, id: string) {
        event.preventDefault();
        event.stopPropagation();

        this.latestGlobalSearchesServices.remove(id);
        this.autoCompleteDirective.manuallyFocus();
    }

    onOptionSelect(query: string) {
        if (this.globalSearchValid) {
            this.latestGlobalSearchesServices.add(createLatestGlobalSearch({
                id: query
            }));

            const url = {
                commands: ['/app', 'feeds'],
                queryParams: { grouping: 'search', query }
            };
            this.navigatorService.navigate(url.commands, { replaceUrl: true, queryParams: { ...url.queryParams } });


            if (this.categoriesQuery.getActiveId() === 'search') {
                this.contentsService.loadSearchResults(query);
            }

            this.autoCompleteDirective.nativeElement.blur();
        }
    }

    onClickClearSearch(event: MouseEvent) {
        event.preventDefault();
        this.globalSearchForm.setValue({ query: '' });
    }
}

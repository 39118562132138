import { Observable, PartialObserver } from 'rxjs';
import { take, filter } from 'rxjs/operators';

export function runOnce(observable: Observable<any>, observer?: PartialObserver<any>) {
    observable.pipe(take(1)).subscribe(observer);
}

export function truthy<T>(value: T) {
    return !!value;
}

export function filterTruthy<T>() {
    return filter(truthy);
}

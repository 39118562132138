import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { ContentsStore, ContentsState } from './contents.store';
import { ApplicationViewQuery } from '../../../../shared/state/application-view/application-view.query';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { truthy } from '../../../../shared/helpers/observable.helper';
import { has, keys, get } from 'lodash-es';
import { Observable } from 'rxjs';
import { ContentAttachment } from './content.model';

@Injectable({ providedIn: 'root' })
export class ContentsQuery extends QueryEntity<ContentsState> {
    contentsQuery$ = this.applicationViewQuery.contentsQuery$;
    isContentsLoading$ = this.selectLoading();
    allLoadedContents$ = this.selectAll();
    errorContents$ = this.selectError();
    activeContent$ = this.selectActive();
    openedContent$ = this.select(state => state.opened); // .pipe(switchMap(ids => this.selectMany(ids)));
    currentNewContent$ = this.select(state => state.contentEditing.currentNewContent);
    isNewContentActive$ = this.currentNewContent$.pipe(map(content => !!content));

    showNoMoreMessage$ = this.select(state => state.ui.showNoMoreMessage);
    showHasNewContents$ = this.select(state => state.ui.showHasNewContents);
    editContents$ = this.select(state => state.contentEditing.editContents);

    currentSessionId$ = this.select(state => state.currentSessionId);
    loadingStates$ = this.select(state => state.loadingStates);
    singleContentLoadingState$ = this.getLoadingStateByPath$(['CONTENT', 'SINGLE', 'LOADING']);
    isContentRemoveInProgress$ = this.getLoadingStateByPath$(['CONTENT', 'REMOVE']);

    isContentEditorProductPreviewLoading$ = this.getLoadingStateByPath$(['CONTENT', 'PRODUCT_INFO']);
    contentEditorProductPreview$ = this.select(state => state.contentEditorPreviewProduct);
    contentEditorProductPreviewError$ = this.select(state => state.contentEditorPreviewError);

    constructor(protected store: ContentsStore, private applicationViewQuery: ApplicationViewQuery) {
        super(store);
    }

    getLoadSingleContentError(contentId: string) {
        return get(this.getValue().loadSingleContentError, contentId, null);
    }

    getLoadSingleContentError$(contentId: string) {
        return this.select(state => state.loadSingleContentError[contentId]);
    }

    getLoadingStateByPath$(path: string | string[]): Observable<boolean> {
        return this.loadingStates$.pipe(map(loadingStates => get(loadingStates, path, false)));
    }

    getLoadingState() {
        return this.getValue().loading;
    }

    isLoadingStateByPath(path: string | string[]) {
        return get(this.getValue().loadingStates, path, false);
    }

    isSingleContentLoading() {
        return this.isLoadingStateByPath(['CONTENT', 'SINGLE', 'LOADING']);
    }

    isContentWithIdInEdit(id: string) {
        return has(this.getValue().contentEditing.editContents, id);
    }

    isContentRemoveInProgress() {
        return this.isLoadingStateByPath(['CONTENT', 'REMOVE']);
    }

    getOpenedContentTabs() {
        const state = this.getValue();

        const editTabs = keys(state.contentEditing.editContents).map(id => 'EDIT_' + id);
        const openedTabs = state.opened.map(tab => tab.id);
        const allTabs = [...editTabs, ...openedTabs];

        if (state.contentEditing.currentNewContent) {
            allTabs.push('NEW_CONTENT');
        }

        return allTabs;
    }

    hasOpenedContent(id: string) {
        const opened = this.getOpenedContent(id);
        return !!opened;
    }

    getOpenedContent(id: string) {
        const opened = this.getValue().opened || [];

        return opened.find(content => content.id === id);
    }

    getCurrentSessionId() {
        return this.getValue().currentSessionId;
    }

    getAttachmentById(attachmentId: string) {
        const state = this.getValue();
        const openedContent = state.opened[0];

        if (openedContent && openedContent.attachments && openedContent.attachments.length) {
            const attachments = openedContent.attachments as ContentAttachment[];

            const foundAttachment = attachments.find(a => a.id === attachmentId);
            return foundAttachment;
        }

        return null;
    }
}

import { Injectable } from '@angular/core';
import { ID, Store, StoreConfig } from '@datorama/akita';
import { Tab, TabGroup } from './tab-manager.model';

export interface TabManagerState {
    groups: { [key: string]: TabGroup };
    splitViews: number;
}

export function createInitialState(): TabManagerState {
    return {
        groups: {},
        splitViews: 2
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tab-manager' })
export class TabManagerStore extends Store<TabManagerState> {
    constructor() {
        super(createInitialState());
    }

    setUpTabManagerGroup(id: string, tabs = [], defaultTabId = null) {
        this.update(state => {
            if (state.groups.hasOwnProperty(id)) {
                defaultTabId = state.groups[id].activeTabId;
            }

            return {
                ...state,
                groups: {
                    ...state.groups,
                    [id]: {
                        id,
                        activeTabId: defaultTabId,
                        tabs
                    }
                }
            };
        });
    }

    setTabActiveInGroup(groupId: string, tabId: string) {
        this.update(state => {
            const groupObj = state.groups[groupId] ? { ...state.groups[groupId] } : {} as TabGroup;
            groupObj.activeTabId = tabId;

            return {
                ...state,
                groups: {
                    ...state.groups,
                    [groupId]: {
                        ...groupObj
                    }
                }
            };
        });
    }
}

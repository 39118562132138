import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { langToLocaleMapping } from '../../models/lang';

@Component({
    selector: 'app-language-switcher',
    templateUrl: './language-switcher.component.html'
})
export class LanguageSwitcherComponent implements OnInit {
    @ViewChild('template', { static: true }) template;

    languages: AvailableLangs;
    activeLang: string;

    constructor(
        private translocoService: TranslocoService,
        private translocoLocaleService: TranslocoLocaleService,
        private viewContainerRef: ViewContainerRef
    ) {
        this.languages = this.translocoService.getAvailableLangs();
        this.activeLang = this.translocoService.getActiveLang();
    }

    ngOnInit() {
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    onLanguageClick(language: string) {
        this.activeLang = language;
        this.translocoService.setActiveLang(language);
        this.translocoLocaleService.setLocale(langToLocaleMapping[language]);
        localStorage.setItem('language', language);
        // window.location.reload();
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert, AlertType } from '../../state/alerts/alert.model';
import { alertTypeMap } from './alert-panel-item.component';


@Component({
    selector: 'app-alert-panel-simple-item',
    template: `
        <div class="alert-panel-item__icon">
            <clr-icon *ngIf="alertInfo.icon" [attr.shape]="alertInfo.icon"
                      [class]="'is-solid ' + ('is-' + alertInfo.className)"
                      size="20"
            ></clr-icon>
        </div>

        <div class="alert-panel-item__content">
            <strong>{{alert.title}}</strong>
            <br>

            {{alert.text}}
        </div>
    `
})
export class AlertPanelSimpleItemComponent implements OnInit {
    @Input()
    alert: Alert;

    constructor() {
    }

    get alertInfo() {
        return alertTypeMap[this.alert.type];
    }

    ngOnInit() {
    }
}

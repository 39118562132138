<ng-template #template>
    <clr-dropdown>
        <button class="nav-icon" clrDropdownTrigger aria-label="toggle settings menu">
            <clr-icon shape="image-gallery"></clr-icon>
            <clr-icon shape="caret down"></clr-icon>
        </button>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
            <button [ngClass]="{'active': theme === currentTheme}" *ngFor="let theme of themes"
                    (click)="onThemeClick(theme);"
                    clrDropdownItem>{{theme | titlecase}}</button>
        </clr-dropdown-menu>
    </clr-dropdown>
</ng-template>

import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {CategoriesState, GroupsStore} from './groups.store';
import {map, switchMap} from 'rxjs/operators';
import {HiddenFeature, searchGroupDefinition} from './group.model';
import {filterTruthy} from '../../../../shared/helpers/observable.helper';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class GroupsQuery extends Query<CategoriesState> {
    isLoadingGroups$ = this.selectLoading();
    hierarchicalGroupList$ = this.select(state => state.groups).pipe(
        filterTruthy()
    );
    hierarchicalContentCreatingGroupList$ = this.select(state => state.contentCreationGroups);

    groupListItems$ = this.select(state => state.entities);
    selectedGroupId$ = this.select(state => state.activeGroup);
    flashGroups$ = this.select(state => state.flashGroups);
    selectedGroup$ = this.selectedGroupId$.pipe(switchMap(group => {
        if (group === 'search') {
            return of({...searchGroupDefinition});
        }

        return this.select(state => state.entities.find(item => item.id === group));
    }));

    groupsMeta$ = this.select(state => state.groupsMeta);

    constructor(protected store: GroupsStore) {
        super(store);
    }

    getActiveGroup() {
        const group = this.getActiveId();
        return this.getValue().entities.find(item => item.id === group);
    }

    getActiveId() {
        return this.getValue().activeGroup;
    }

    getGroupByCategoryId(id: string) {
        return this.getValue().entities.find(entity => (entity.defaultCategory === id) || (('' + entity.label).toUpperCase() === id));
    }

    selectCount(): Observable<number> {
        return this.select(state => state.entities).pipe(map((entities) => entities ? entities.length : 0));
    }

    isFeatureHidden(feature: HiddenFeature) {
        const featureConfiguration = this.getValue().groups?.featureConfiguration;
        const hiddenFeatures = featureConfiguration && featureConfiguration.hiddenFeatures;

        return hiddenFeatures && hiddenFeatures.includes(feature);
    }

    getEnabledByDefault() {
        return this.getValue().groups?.notifyConfiguration?.enabledByDefault ?? false;
    }
}
